import ReactHookFormStepper from '../../common/react-hook-form-stepper/ReactHookFormStepper';
import QuoteStepOne from './QuoteStepOne';
import { t } from 'i18next';
import quoteTypes from '../../const-values/quoteTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  publicQuotesDefaultValue,
  contractorQuoteDefaultValues,
  supplierQuoteDefaultValues,
  sharedDefaultValues
} from './quoteDefaultValues';
import QuoteStepTwo from './QuoteStepTwo';
import getQuoteStepTwoValidationSchema from './getQuoteStepTwoValidationSchema';
import getQuoteStepOneValidationSchema from './getQuoteStepOneValidationSchema';
import {
  createPublicContractorQuoteAction,
  createSupplierQuoteAction
} from '../../redux/quote-slice/quoteSliceThunkApi';
import checkRes from '../../utils/checkRes';
import { resetCartAction } from '../../redux/cart-slice/cartSlice';

const OrderStepperForm = ({ step, setStep }) => {
  const quoteType = useSelector((store) => store.quote.quoteType);
  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);
  const negotiationPrice = useSelector((store) => store.cart.negotiationPrice);
  const sharedErrTrans = (key, errType) =>
    t(`requestQuoteForm.${key}.errors.${errType}`);
  const fetchedSingleCompany = useSelector(
    (store) => store.companies.fetchedSingleCompany
  );

  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append('subject', data?.subject || '');
    formData.append('country_id', data?.country_id || '');
    formData.append('city_id', data?.city_id || '');
    formData.append('description', data?.description || '');
    if (data?.service_id && data?.subService_id) {
      formData.append('service_id', data?.subService_id || '');
    }
    formData.append('endUserType', data?.endUserType || '');
    //
    //
    //
    if (
      (quoteType === quoteTypes?.publicQuote ||
        quoteType === quoteTypes?.contractorQuote) &&
      data?.products?.length > 0
    ) {
      formData.append('products', JSON.stringify(data.products));
    } else if (
      quoteType === quoteTypes?.supplierQuote &&
      cartProducts?.length > 0
    ) {
      const arr = cartProducts.map((p) => ({
        product_id: p?.product_id || '',
        quantity: p?.quantity || '',
        unit_id: p?.unit?.id || '',
        option_id: p?.option?.id || ''
      }));
      formData.append('products', JSON.stringify(arr));
    }
    //
    //
    //

    if (data?.orderFiles?.length > 0) {
      for (let file of data.orderFiles) {
        formData.append('orderFiles[]', file);
      }
    }
    //
    //
    if (quoteType === quoteTypes?.publicQuote) {
      formData.append('companyType', data.companyType || '');
      formData.append('type', 1);
    } else if (
      quoteType === quoteTypes?.contractorQuote ||
      quoteType === quoteTypes?.supplierQuote
    ) {
      formData.append('company_id', fetchedSingleCompany?.id || '');
      formData.append('type', 2);
    }
    //
    //
    if (quoteType === quoteTypes?.supplierQuote && negotiationPrice) {
      formData.append('negotiatePrice', negotiationPrice);
    }
    //
    //

    if (quoteType === quoteTypes?.supplierQuote) {
      const action = await dispatch(
        createSupplierQuoteAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        dispatch(resetCartAction());
        setStep((prev) => prev + 1);
      }
    } else {
      const action = await dispatch(
        createPublicContractorQuoteAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        dispatch(resetCartAction());
        setStep((prev) => prev + 1);
      }
    }
  };

  const getDefaultValues = () => {
    if (quoteType === quoteTypes?.publicQuote) {
      return publicQuotesDefaultValue;
    } else if (quoteType === quoteTypes?.contractorQuote) {
      return contractorQuoteDefaultValues;
    } else if (quoteType === quoteTypes?.supplierQuote) {
      return supplierQuoteDefaultValues;
    }

    return sharedDefaultValues;
  };

  return (
    <ReactHookFormStepper
      step={step}
      setStep={setStep}
      className="quote-stepper-form"
      defaultValues={getDefaultValues()}
      fetchedDefaultValues={null}
      // defaultValues={
      //   fetchedSingleStoreDetails
      //     ? fetchedStoreDefaultValues
      //     : newStoreDefaultValues
      // }
      isSubmittingForm={false}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      <QuoteStepOne
        validationSchema={getQuoteStepOneValidationSchema(
          quoteType,
          sharedErrTrans
        )}
        step={0}
      />

      <QuoteStepTwo
        validationSchema={getQuoteStepTwoValidationSchema(
          quoteType,
          sharedErrTrans
        )}
        step={1}
      />
    </ReactHookFormStepper>
  );
};

export default OrderStepperForm;
