/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import { forgetPasswordResetPasswordApi } from '../../apis/auth/forgetPassApis';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';

const ForgetPasswordFormResetPassword = () => {
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);
  const schema = Yup.object().shape({
    password: Yup.string().required(sharedT('password.errors.required')),
    password_confirm: Yup.string()
      .required(sharedT('confirmPassword.errors.required'))
      .oneOf([Yup.ref('password')], sharedT('confirmPassword.errors.match'))
  });
  const {
    setForgetPasswordModalOpened,
    user,
    setUser,
    // setSuccessLock,
    setForgetPasswordFormResetPasswordAppended,
    setIsSubmitting
  } = useContext(ForgetPasswordContext);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      password: '',
      password_confirm: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ email: '' });
    };
  }, []);
  const [passwrodVisible, setPasswordVisible] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmitting(true);
    customApiRequest(
      forgetPasswordResetPasswordApi(user?.token, data),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          if (user && typeof user === 'object') {
            setUser({
              ...user,
              isUserChangedForgettedPassword: true
            });
          }
          setForgetPasswordModalOpened(false);
          setForgetPasswordFormResetPasswordAppended(false);
          // resetContext();
          reset({
            password: '',
            password_confirm: ''
          });
          // setSuccessLock(true);
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="login-password-field-wrap">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{sharedT('password.label')}</p>
          </div>

          {watch('password') && (
            <button
              type="button"
              className="eye-icon-btn"
              style={{
                left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                right: i18n.dir() === 'ltr' ? '14px' : 'auto'
              }}
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
            </button>
          )}
        </div>

        <div className="login-password-field-wrap">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="password_confirm"
              type={passwrodVisible ? 'text' : 'password'}
              errorMsg={errors?.password_confirm?.message}
              validateStatus={errors?.password_confirm ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{sharedT('confirmPassword.label')}</p>
          </div>

          {watch('password_confirm') && (
            <button
              type="button"
              className="eye-icon-btn"
              style={{
                left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                right: i18n.dir() === 'ltr' ? '14px' : 'auto'
              }}
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
            </button>
          )}
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          // loading={isLoading}
        >
          {sharedT('changeBtn')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgetPasswordFormResetPassword;
