/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  getSingleCompanyAction,
  getSupplierProductsAction
} from '../../redux/companies-slice/companiesSliceThunkApis';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import { t } from 'i18next';
import routerLinks from '../../components/app/routerLinks';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import './SupplierProductsPage.scss';
import CustomImage from '../../common/custom-image/CustomImage';
import { setSelectedSupplierProduct } from '../../redux/companies-slice/companiesSlice';
import SelectedSupplierProduct from './SelectedSupplierProduct';
import { useMemo } from 'react';
import imgsSrcs from '../../helpers/imgsSrcs';
import { calcCartTotal } from '../../redux/cart-slice/cartSlice';
import checkRes from '../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const SupplierProductsPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const fetchedSingleCompany = useSelector(
    (store) => store.companies.fetchedSingleCompany
  );

  useEffect(() => {
    async function getSingleComp() {
      try {
        const action = await dispatch(
          getSingleCompanyAction({
            filterObj: { company_id: params?.company_id }
          })
        );
        const res = action?.payload?.res;
        if (!checkRes(res)) navigate(routerLinks?.homePage);
      } catch (error) {}
    }

    getSingleComp();
  }, [i18n.language]);

  const isLoadingSupplierProducts = useSelector(
    (store) => store.companies.isLoadingSupplierProducts
  );
  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);
  const cartSupplierId = useSelector((store) => store.cart.cart.cartSupplierId);
  const cartServiceId = useSelector((store) => store.cart.cart.cartServiceId);
  const cartTotal = useSelector((store) => store.cart.cart.cartTotal);

  // const [total, setTotal] = useState(0);
  useEffect(() => {
    dispatch(calcCartTotal());
    // if (cartProducts?.length > 0) {
    //   let total = 0;
    //   total = cartProducts.reduce((acc, curr) => {
    //     return acc + Number(curr.price) * Number(curr.quantity);
    //   }, total);

    //   setTotal(total);
    // }
  }, [cartProducts]);

  const fetchedSupplierProducts = useSelector(
    (store) => store.companies.fetchedSupplierProducts
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (params?.company_id && params?.service_id) {
      dispatch(
        getSupplierProductsAction({
          filterObj: {
            company_id: params?.company_id,
            service_id: params?.service_id
            // company_id: 3,
            // service_id: 1
          }
        })
      );
    }
  }, []);

  const PorductCard = ({ pro }) => {
    return (
      <div className="pro-wrapper">
        <CustomImage className="pro-img" src={pro?.image} />
        <p className="count-p">
          {pro?.options?.length} {t('supplierProductsPage.products')}
        </p>
        <p className="pro-name">{pro?.name}</p>

        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setSelectedSupplierProduct(pro));
          }}
          className="view-btn"
        >
          {t('supplierProductsPage.view')}
        </button>
      </div>
    );
  };

  const renderProductsList = useMemo(() => {
    return () =>
      fetchedSupplierProducts.map((pro) => {
        return <PorductCard key={pro.id} pro={pro} />;
      });
  }, [fetchedSupplierProducts]);

  if (isLoadingSupplierProducts) {
    return (
      <div className="supplier-products-page">
        <div
          className="custom-container"
          style={{
            paddingTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }

  if (fetchedSupplierProducts?.length === 0 || !fetchedSupplierProducts) {
    return (
      <div className="supplier-products-page">
        <CustomBreadcrumb
          arr={[
            {
              title: t('breadcrumbs.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('breadcrumbs.suppliers'),
              isLink: true,
              to: generalRouterLinks?.suppliersRoute
            },
            {
              title: t('breadcrumbs.supplierDetails'),
              isLink: true,
              to: `${generalRouterLinks?.singleCompanyRoute}?company_id=${
                params?.company_id || ''
              }`
            },
            {
              title: t('breadcrumbs.supplierProducts'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <h2
            style={{
              margin: '42px auto'
            }}
          >
            No Products found!!!
          </h2>
        </div>
      </div>
    );
  } else if (fetchedSupplierProducts?.length >= 0) {
    return (
      <div className="supplier-products-page">
        <div className="custom-container">
          <div className="bread-crumb-cart-wrapper">
            <CustomBreadcrumb
              arr={[
                {
                  title: t('breadcrumbs.home'),
                  isLink: true,
                  to: routerLinks?.homePage
                },
                {
                  title: t('breadcrumbs.suppliers'),
                  isLink: true,
                  to: generalRouterLinks?.suppliersRoute
                },
                {
                  // title: t('breadcrumbs.supplierDetails'),
                  title:
                    fetchedSingleCompany?.name ||
                    t('breadcrumbs.supplierDetails'),
                  isLink: true,
                  to: `${generalRouterLinks?.singleCompanyRoute}?company_id=${
                    params?.company_id || ''
                  }`
                },
                {
                  // title: t('breadcrumbs.supplierProducts'),
                  title:
                    fetchedSupplierProducts[0]?.service?.name ||
                    t('breadcrumbs.supplierProducts'),
                  isLink: false
                }
              ]}
            />

            {cartSupplierId === params?.company_id &&
              cartProducts?.length > 0 && (
                <div className="cart-wrapper">
                  <div>
                    <div className="icon-wrap">
                      <img src={imgsSrcs?.cartImg} alt="" />
                      Cart
                    </div>
                    <div className="totals-wrap">
                      <div className="total-q">
                        {cartProducts.reduce(
                          (acc, curr) => acc + curr.quantity,
                          0
                        )}{' '}
                        items -{' '}
                      </div>
                      <div className="total-price">
                        {cartTotal} {t('currency.sar')}
                      </div>
                    </div>
                  </div>

                  <Link
                    className="cart-link"
                    to={generalRouterLinks?.userCartRoute(
                      cartSupplierId,
                      cartServiceId
                    )}
                  >
                    view cart
                  </Link>
                </div>
              )}
          </div>

          <section className="pro-section">
            <div className="sec-header">
              <p className="main-p">{t('supplierProductsPage.products')}</p>
            </div>

            <div className="supp-products-list">{renderProductsList()}</div>
          </section>
        </div>

        <SelectedSupplierProduct />
      </div>
    );
  }

  return null;
};

export default SupplierProductsPage;
