/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../common/icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import './MainAppSearch.scss';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import debounce from 'lodash.debounce';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useSelector } from 'react-redux';
import getAllCompaniesApi from '../../apis/companies-apis/getAllCompaniesApi';
import checkRes from '../../utils/checkRes';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';

const MainAppBarSearch = ({ location }) => {
  const { i18n, t } = useTranslation();
  const {
    isLoadingStores,
    allFetchedStores,
    fetchStoresCount,
    setIsLoadingStores,
    setAllFetchedStores,
    setFetchStoresCount
  } = useContext(SharedAppContext);
  // handle search
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState('');
  const user = useSelector((store) => store.user.user);

  const changeHandler = (event) => {
    setQuery(event.target.value);
  };

  console.log('all : ', allFetchedStores);
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (query) {
      setFetchStoresCount((prev) => prev + 1);
      setIsLoadingStores(true);
      customApiRequest(
        getAllCompaniesApi({
          searchText: query
        }),
        (res) => {
          if (isMounted) {
            setIsLoadingStores(false);
            if (checkRes(res) && res?.data?.data?.data?.length > 0) {
              setAllFetchedStores(res.data.data.data);
            } else {
              setAllFetchedStores([]);
            }
          }
        },
        (error) => {
          setIsLoadingStores(false);
        }
      );
    }

    return () => (isMounted = false);
  }, [query]);

  console.log(allFetchedStores);

  // console.log('all fetched stores : ', allFetchedStores);
  // console.log('search results arr  : ', searchStoresResultArr);
  const navigate = useNavigate();
  const renderServicesMenu = () => {
    if (isLoadingStores) {
      return [
        {
          key: 'item-1',
          label: t('main_app_search.searching')
        }
      ];
    } else if (allFetchedStores?.length > 0) {
      return allFetchedStores.map((store) => ({
        key: store?.id,
        onClick: () => {
          setSearchText(store?.name);
          navigate(
            `${generalRouterLinks?.singleCompanyRoute}?company_id=${
              store?.id || ''
            }`
          );
        },
        label: store?.name
      }));
    } else if (allFetchedStores?.length === 0) {
      return [
        {
          key: 'item-1',
          label: `${t('main_app_search.notFound')} !!!`
        }
      ];
    }
    return [
      {
        key: 'item-1',
        label: `${t('main_app_search.notFound')} !!!`
      }
    ];
  };

  // handle submit
  // const navigate = useNavigate();
  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   if (selectedStore) {
  //     navigate(userRouterLinks?.singleStoreRoute(null, selectedStore?.id));
  //   }
  // };

  return (
    <>
      <Dropdown
        arrow
        trigger={['click']}
        // disabled={loadingSignout}
        menu={{
          items: renderServicesMenu()
        }}
      >
        <form
          className={`main-app-search-form ${i18n.dir()}`}
          // onSubmit={handleFormSubmit}
        >
          <label>
            <div className="icon-wrap">
              {isLoadingStores && fetchStoresCount > 0 ? (
                <LoadingOutlined />
              ) : (
                <SearchIcon color="#999" />
              )}
            </div>
            <input
              type="text"
              name="main_app_search"
              placeholder={t('main_app_search.placeholder')}
              onChange={(e) => {
                setSearchText(e.target.value);
                debouncedChangeHandler(e);
              }}
              value={searchText || ''}
            />

            <div className="btn-map-wrapper">
              <Button
                htmlType="button"
                className={`search-btn ${i18n.dir()}`}
                type="primary"
              >
                {t('main_app_search.search')}
              </Button>
            </div>
          </label>
        </form>
      </Dropdown>
    </>
  );
};

export default MainAppBarSearch;
