import { Rate } from 'antd';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomImage from '../../common/custom-image/CustomImage';
import HeartIcon from '../../common/icons/HeartIcon';
import HeartIconFilled from '../../common/icons/HeartIconFIlled';
import { addCompanyToFavAction } from '../../redux/companies-slice/companiesSliceThunkApis';
import { LoadingOutlined } from '@ant-design/icons';
import './CompanyInfoCard.scss';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import accountTypes, { companyTypes } from '../../const-values/accountTypes';
import quoteTypes from '../../const-values/quoteTypes';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';

const CompanyInfoCard = ({
  id,
  image,
  cardCompType,
  compTypeName,
  name,
  rate,
  desc,
  is_fav
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const isSubmittingFav = useSelector(
    (store) => store.companies.isSubmittingFav
  );

  const renderFavBtn = () => {
    if (isSubmittingFav) {
      return (
        <div className="submitting-fav-wrapper">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <button
        className="fav-btn"
        onClick={() => {
          const formData = new FormData();
          formData.append('company_id', id);
          dispatch(
            addCompanyToFavAction({
              dataToBeSubmitted: formData
            })
          );
        }}
      >
        {is_fav ? (
          <HeartIconFilled color="#246db4" />
        ) : (
          <HeartIcon color="#98A6B3" />
        )}
      </button>
    );
  };
  return (
    <div className="company-info-card">
      <CustomImage className="company-img" src={image} alt="company" />

      <div className="main-data">
        <div className="type">{compTypeName}</div>
        <div className="name">{name}</div>
        <Rate
          className="rate"
          disabled
          allowClear={false}
          allowHalf={true}
          value={rate || 0}
        />
        <div className="desc">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper tempus
          volutpat nibh porttitor condimentum cursus sed. Laoreet a, purus nunc
          bibendum nunc. Auctor lacus dui pretium dui, facilisi a et. Adipiscing
          volutpat felis, tincidunt nisi, mi pulvinar. Netus adipiscing sit
          ultrices at
        </div>

        <div className="action-btns">
          {cardCompType !== companyTypes()?.supplier && (
            <button
              className="req-quote-btn"
              // check company type
              onClick={() => {
                if (user) {
                  dispatch(setQuoteType(quoteTypes?.contractorQuote));
                  dispatch(setQuoteModalOpened(true));
                } else {
                  dispatch(setNotAuthedModalOpened(true));
                }
              }}
            >
              {t('singleCompPage.reqQuotationBtn')}
            </button>
          )}

          <button className="review-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M9.63524 14.7346C9.39588 14.5944 9.10414 14.5944 8.86478 14.7346L5.17935 16.8928C4.59217 17.2367 3.88946 16.7026 4.02062 16.012L4.84361 11.6781C4.897 11.3967 4.80685 11.1064 4.6055 10.9116L1.50417 7.91149C1.01006 7.43348 1.27845 6.56927 1.94678 6.48635L6.14114 5.96593C6.41356 5.93213 6.64953 5.75274 6.76444 5.49219L8.53385 1.47947C8.81572 0.840176 9.6843 0.840176 9.96617 1.47947L11.7356 5.49219C11.8505 5.75274 12.0865 5.93213 12.3589 5.96593L16.5532 6.48635C17.2216 6.56927 17.49 7.43348 16.9958 7.91149L13.8945 10.9116C13.6932 11.1064 13.603 11.3967 13.6564 11.6781L14.4794 16.012C14.6106 16.7026 13.9078 17.2367 13.3206 16.8928L9.63524 14.7346Z"
                stroke="#246DB4"
              />
            </svg>

            {t('singleCompPage.addReviewBtn')}
          </button>
        </div>
      </div>

      {user && renderFavBtn()}
    </div>
  );
};

export default CompanyInfoCard;
