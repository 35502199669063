import { createSlice } from '@reduxjs/toolkit';

import {
  getHomeAction,
  getCountriesAction,
  getServicesAction,
  getCompanyTypesAction,
  getSliderAction,
  getUnitsAction,
  getPublicQuotesAction,
  getSinglePublicQuoteAction
} from './generalSliceThunkApis';

const initialState = {
  //
  isLoadingHome: false,
  fetchedHomeData: null,
  //
  //
  isLoadingCountries: false,
  fetchedCountriesData: null,
  //
  //
  isLoadingServices: false,
  fetchedServicesData: [],
  servicesPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  //
  //
  isLoadingCompanyTypes: false,
  fetchedCompanyTypesData: null,
  //
  //
  isLoadingSlider: false,
  fetchedSliderData: null,
  //
  //
  isLoadingUnits: false,
  fetchedUnitsData: null,
  //,
  isLoadingPublicQuotes: false,
  fetchedPublicQuotes: [],
  publicQuotesPagination: {
    current_page: 1,
    per_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////
  isLoadingPublicSingleQuote: false,
  fetchedPublicSingleQuote: null,
  fetchPublicSingleQuoteCount: 0
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setFetchPublicSingleQuoteCount: (sliceState, action) => {
      sliceState.fetchPublicSingleQuoteCount += 1;
    }
  },
  extraReducers: {
    // home
    [getHomeAction.pending]: (sliceState, action) => {
      sliceState.isLoadingHome = true;
    },
    [getHomeAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingHome = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedHomeData = data;
    },
    [getHomeAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingHome = false;
    },
    ///////////////////
    // countries
    [getCountriesAction.pending]: (sliceState, action) => {
      sliceState.isLoadingCountries = true;
    },
    [getCountriesAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingCountries = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedCountriesData = data;
    },
    [getCountriesAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingCountries = false;
    },
    ///////////////////
    // services
    [getServicesAction.pending]: (sliceState, action) => {
      sliceState.isLoadingServices = true;
    },
    [getServicesAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingServices = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedServicesData = data?.data;
      if (data?.pagination) sliceState.servicesPagination = data.pagination;
    },
    [getServicesAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingServices = false;
    },
    ///////////////////
    // company types
    [getCompanyTypesAction.pending]: (sliceState, action) => {
      sliceState.isLoadingCompanyTypes = true;
    },
    [getCompanyTypesAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingCompanyTypes = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedCompanyTypesData = data;
    },
    [getCompanyTypesAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingCompanyTypes = false;
    },
    ///////////////////
    // slider
    [getSliderAction.pending]: (sliceState, action) => {
      sliceState.isLoadingSlider = true;
    },
    [getSliderAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingSlider = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedSliderData = data;
    },
    [getSliderAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingSlider = false;
    },
    ///////////////////
    // units
    [getUnitsAction.pending]: (sliceState, action) => {
      sliceState.isLoadingUnits = true;
    },
    [getUnitsAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingUnits = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedUnitsData = data;
    },
    [getUnitsAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingUnits = false;
    },
    ///////////////////
    ///////////////////
    // public quotes
    [getPublicQuotesAction.pending]: (sliceState, action) => {
      sliceState.isLoadingPublicQuotes = true;
    },
    [getPublicQuotesAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingPublicQuotes = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedPublicQuotes = data?.data;
      if (data?.pagination) sliceState.publicQuotesPagination = data.pagination;
    },
    [getPublicQuotesAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingPublicQuotes = false;
    },
    ///////////////////
    // public single quote
    [getSinglePublicQuoteAction.pending]: (sliceState, action) => {
      sliceState.isLoadingPublicSingleQuote = true;
    },
    [getSinglePublicQuoteAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingPublicSingleQuote = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedPublicSingleQuote = data;
    },
    [getSinglePublicQuoteAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingPublicSingleQuote = false;
    }
    ///////////////////
  }
});

const generalReducer = generalSlice.reducer;

export const { setFetchPublicSingleQuoteCount } = generalSlice.actions;

export default generalReducer;
