import { createAsyncThunk } from '@reduxjs/toolkit';
import getCompanyTypesApi from '../../apis/general/getCompanyTypesApi';
import getCountriesApi from '../../apis/general/getCountriesApi';
import getHomeApi from '../../apis/general/getHomeApi';
import getServicesApi from '../../apis/general/getServicesApi';
import getSlidesApi from '../../apis/general/getSlidesApi';
import getUnitsApi from '../../apis/general/getUnitsApi';
import getPublicQuotesApi from '../../apis/general/publicQuotesApi';
import publicSingleQuoteApi from '../../apis/general/publicSingleQuoteApi';
import customApiRequest from '../../helpers/customApiRequest';

// get home action
export const getHomeAction = createAsyncThunk(
  'general/getHomeAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getHomeApi(),
      thunkAPI
    });
  }
);

// countries
export const getCountriesAction = createAsyncThunk(
  'general/getCountriesAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getCountriesApi(),
      thunkAPI
    });
  }
);

// services
export const getServicesAction = createAsyncThunk(
  'general/getServicesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getServicesApi(filterObj),
      thunkAPI
    });
  }
);

// company types
export const getCompanyTypesAction = createAsyncThunk(
  'general/getCompanyTypesAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getCompanyTypesApi(),
      thunkAPI
    });
  }
);

// get slider action
export const getSliderAction = createAsyncThunk(
  'general/getSliderAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSlidesApi(),
      thunkAPI
    });
  }
);

// get units action
export const getUnitsAction = createAsyncThunk(
  'general/getUnitsAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getUnitsApi(),
      thunkAPI
    });
  }
);

// public quotes
export const getPublicQuotesAction = createAsyncThunk(
  'general/getPublicQuotesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getPublicQuotesApi(filterObj),
      thunkAPI
    });
  }
);

// single public quote
export const getSinglePublicQuoteAction = createAsyncThunk(
  'general/getSinglePublicQuoteAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await publicSingleQuoteApi(filterObj),
      thunkAPI
    });
  }
);
