/* eslint-disable eqeqeq */
import accountTypes from '../../const-values/accountTypes';
import appPages from '../../const-values/appPages';

const mainAppBarLinks = (i18n, t, user) => {
  const {
    homePage,
    aboutUsPage,
    merchentDashboard,
    contactUsPage,
    pricesPage,
    companiesPage
  } = appPages(i18n, t, user);

  const sharedLinks = () => {
    return [
      {
        id: homePage?.id,
        name: homePage?.name,
        link: homePage?.link,
        icon: homePage?.icon ? <homePage.icon /> : null,
        sub: homePage?.sub,
        end: true
      }
    ];
  };

  const sharedToUserLinks = () => {
    return [
      {
        id: companiesPage?.id,
        name: companiesPage?.name,
        link: companiesPage?.link,
        icon: companiesPage?.icon ? <companiesPage.icon /> : null,
        sub: companiesPage?.sub
      },

      // {
      //   id: suppliersPage?.id,
      //   name: suppliersPage?.name,
      //   link: suppliersPage?.link,
      //   icon: suppliersPage?.icon ? <suppliersPage.icon /> : null,
      //   sub: suppliersPage?.sub
      // },
      // {
      //   id: contractorsPage?.id,
      //   name: contractorsPage?.name,
      //   link: contractorsPage?.link,
      //   icon: contractorsPage?.icon ? <contractorsPage.icon /> : null,
      //   sub: contractorsPage?.sub
      // },
      {
        id: pricesPage?.id,
        name: pricesPage?.name,
        link: pricesPage?.link,
        icon: pricesPage?.icon ? <pricesPage.icon /> : null,
        sub: pricesPage?.sub
      },
      {
        id: aboutUsPage?.id,
        name: aboutUsPage?.name,
        link: aboutUsPage?.link,
        icon: aboutUsPage?.icon ? <aboutUsPage.icon /> : null,
        sub: aboutUsPage?.sub
      },
      {
        id: contactUsPage?.id,
        name: contactUsPage?.name,
        link: contactUsPage?.link,
        icon: contactUsPage?.icon ? <contactUsPage.icon /> : null,
        sub: contactUsPage?.sub
      }
    ];
  };

  if (user?.accountType == accountTypes()?.user) {
    return [...sharedLinks(), ...sharedToUserLinks()];
  } else if (
    user?.accountType == accountTypes()?.company ||
    user?.accountType == accountTypes()?.employee
  ) {
    return [
      {
        id: merchentDashboard?.id,
        name: merchentDashboard?.name,
        link: merchentDashboard?.link,
        icon: merchentDashboard?.icon ? <merchentDashboard.icon /> : null,
        sub: merchentDashboard?.sub
      },
      {
        id: aboutUsPage?.id,
        name: aboutUsPage?.name,
        link: aboutUsPage?.link,
        icon: aboutUsPage?.icon ? <aboutUsPage.icon /> : null,
        sub: aboutUsPage?.sub
      },
      {
        id: contactUsPage?.id,
        name: contactUsPage?.name,
        link: contactUsPage?.link,
        icon: contactUsPage?.icon ? <contactUsPage.icon /> : null,
        sub: contactUsPage?.sub
      }
      // {
      //   id: merchentDashboard?.id,
      //   name: merchentDashboard?.name,
      //   link: merchentDashboard?.link,
      //   icon: merchentDashboard?.icon ? <merchentDashboard.icon /> : null,
      //   sub: merchentDashboard?.sub
      // }
    ];
  }
  return [...sharedLinks(), ...sharedToUserLinks()];
};

export default mainAppBarLinks;
