const apisUrls = {
  // auth urls
  signupUrl: '/auth/register',
  checkSignupActiveCode: '/auth/check_active_code',
  resendCode: '/auth/resend_code',
  signinUrl: '/auth/login',
  signOutUrl: '/auth/logout',
  userInfoUrl: '/auth/my_info',
  editUserProfileUrl: '/auth/edit_profile',
  forgetPasswordUrl: '/auth/forget_password',
  checkPasswordCodeUrl: '/auth/check_password_code',
  resetPasswordUrl: '/auth/reset_password',
  changePasswordUrl: '/auth/change_password',
  changeLangUrl: '/auth/change_lang',
  userFavUrl: '/Favourite/myFavourite',
  // end auth urls
  ////////////////////
  // general data urls
  pubicQuotesUrl: (filterObj) =>
    `/General/publicRequests?page=${filterObj?.page || 1}`,
  singlePubicQuoteUrl: (filterObj) =>
    `/General/singlePublicRequests?order_id=${filterObj?.order_id || ''}`,
  countriesUrl: '/General/counties',
  servicesUrl: (filterObj) => `/General/services?page=${filterObj?.page || 1}`,
  companyTypesUrl: '/General/companyTypes',
  sliderUrl: '/General/slider',
  homeUrl: '/General/home',
  unitsUrl: '/General/units',
  // end general data urls
  //////////////////
  // companies urls
  getCompanies: (filterObj) =>
    `/Company/companies?searchText=${filterObj?.searchText || ''}&companyType=${
      filterObj?.companyType || ''
    }&service_id=${filterObj?.service_id || ''}&country_id=${
      filterObj?.country_id || ''
    }&city_id=${filterObj?.city_id || ''}&page=${filterObj?.page || 1}`,
  getSingleCompany: (filterObj) =>
    `/Company/singleCompany?company_id=${filterObj?.company_id || ''}`,
  getSuppliersProducts: (filterObj) =>
    `/Company/getProducts?company_id=${filterObj?.company_id}&service_id=${
      filterObj?.service_id || ''
    }`,
  addCompanyToFav: '/Favourite/addToFavourite',
  // end companies url
  //////////////////
  //  quotes urls
  createQuotePublicOrContractor: '/Order/makeOrderPublicOrContractors',
  createQuoteSupplier: '/Order/makeSupplierOrder',
  userOrders: (filterObj) =>
    `/Order/myOrders?type=${filterObj?.type || ''}&page=${
      filterObj?.page || 1
    }`,
  userSingleOrder: (filterObj) =>
    `/Order/singleOrder?order_id=${filterObj?.order_id || ''}`,
  getOrderCompanies: (filterObj) =>
    `/Offer/getOfferCompany?order_id=${filterObj?.order_id || ''}`,
  getUserCompanyOffers: (filterObj) =>
    `/Offer/getOfferForOrder?order_id=${filterObj?.order_id || ''}&company_id=${
      filterObj?.company_id || ''
    }`,
  userCancelOrder: '/Order/cancelOrder',
  userAnswerOffer: '/Offer/answerOffer',
  getContractorOffers: (filterObj) =>
    `/Offer/getOfferCompanyForOrder?order_id=${filterObj?.order_id || ''}`,
  contractorAnswerOffer: '/Offer/answerOfferSupplier',
  userNegotiationHistory: (filterObj) =>
    `/Order/NegotiatesHistory?order_id=${filterObj?.order_id || ''}`,
  userAnswerNegotiation: '/Order/answerNegotiate',
  // end quotes urls

  // ///////////////////////////
  // ///////////////////////////
  // merchant urls
  // ///////////////////////////
  // ///////////////////////////
  companyOrders: (filterObj) =>
    `/OrderCompany/myOrders?type=${filterObj?.type || ''}&page=${
      filterObj?.page || 1
    }`,
  companySingleOrder: (filterObj) =>
    `/OrderCompany/singleOrder?order_id=${filterObj?.order_id || ''}`,
  companyMakeOffer: '/Offer/makeOffer',
  supplierAcceptOrRejectOrder: '/OrderCompany/acceptOrRejectOrder',
  supplierNegotiationHistory: (filterObj) =>
    `/OrderCompany/NegotiatesHistory?order_id=${filterObj?.order_id || ''}`,
  supplierAnswerNegotiation: '/OrderCompany/answerNegotiate',
  ////////////////////////
  paymentUrl: (filterObj) =>
    `http://bnaa.ahmeds.club/api/Payment/preparePayment?order_id=${
      filterObj?.order_id || ''
    }`
};

export default apisUrls;
