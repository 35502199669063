import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: !localStorage.getItem('cart')
    ? {
        cartSupplierId: '',
        cartServiceId: '',
        cartServiceName: '',
        cartProducts: [],
        cartTotal: 0
      }
    : JSON.parse(localStorage.getItem('cart')),
  ///
  negotiationModalOpened: false,
  negotiationPrice: ''
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProductToCartAction: (sliceState, action) => {
      const supplierId = action.payload.supplierId;
      const serviceId = action.payload.serviceId;
      const cartServiceName = action.payload.cartServiceName;
      const product = action.payload.product;
      const cartTotal = action.payload.cartTotal;

      if (sliceState.cart.cartProducts?.length >= 0) {
        // check if the same supplier and same service  => add or remove product from cart
        if (
          sliceState.cart.cartSupplierId === supplierId &&
          sliceState.cart.cartServiceId === serviceId
        ) {
          let newCart = null;
          // check if the product is not in cart or the product in the cart but not with the same option
          // => then add this product to cart with the new options
          const isProuctInCart = sliceState.cart.cartProducts.find(
            (item) => item.product_id === product?.product_id
          );
          const isProductInCartAndWithSameOptions =
            sliceState.cart.cartProducts.find(
              (item) =>
                item.product_id === product?.product_id &&
                item?.option?.id === product?.option?.id
            );

          if (!isProuctInCart || !isProductInCartAndWithSameOptions) {
            newCart = {
              ...sliceState.cart,
              cartProducts: [...sliceState.cart.cartProducts, product]
            };
          }

          // check if the product in cart and with the same options

          sliceState.cart = newCart;
          localStorage.setItem('cart', JSON.stringify(newCart));
        }

        // if not the same supplier then empty cart first and then add product
        else {
          const objToBeAdded = {
            cartSupplierId: supplierId,
            cartServiceId: serviceId,
            cartServiceName: cartServiceName,
            cartProducts: [product],
            cartTotal: cartTotal
          };
          sliceState.cart = objToBeAdded;
          localStorage.setItem('cart', JSON.stringify(objToBeAdded));
        }
      }
    },
    ////////////////
    incDecProductQty: (sliceState, action) => {
      const btnType = action.payload.btnType;
      const product_id = action.payload.product_id;
      const option_id = action.payload.option_id;

      const objToBeAdded = {
        ...sliceState.cart,
        cartProducts: sliceState.cart.cartProducts.map((productObj) => {
          if (
            productObj.product_id === product_id &&
            productObj?.option?.id === option_id
          )
            return {
              ...productObj,
              quantity:
                btnType === 'inc'
                  ? Number(productObj.quantity) + 1
                  : Number(productObj.quantity) > 1
                  ? Number(productObj.quantity) - 1
                  : productObj.quantity
            };

          return {
            ...productObj
          };
        })
      };
      sliceState.cart = objToBeAdded;
      localStorage.setItem('cart', JSON.stringify(objToBeAdded));
    },
    ////////////////
    calcCartTotal: (sliceState, action) => {
      let total = 0;
      if (sliceState.cart.cartProducts?.length > 0) {
        total = sliceState.cart.cartProducts.reduce((acc, curr) => {
          return acc + Number(curr.price) * Number(curr.quantity);
        }, total);

        sliceState.cart.cartTotal = total;
      }
    },
    ////////////////////
    // remove product form cart
    removeCartProductAction: (sliceState, action) => {
      const product_id = action.payload.product_id;
      const option_id = action.payload.option_id;

      const filterHandler = (obj) => {
        // check is the same product
        if (obj?.product_id === product_id) {
          // if the same product
          // then return the product but without the same option
          return obj?.option?.id !== option_id;
        }

        // not the same product then return obj as it is
        else {
          return obj;
        }
      };

      const objToBeAdded = {
        ...sliceState.cart,
        cartProducts: sliceState.cart.cartProducts.filter((obj) =>
          filterHandler(obj)
        )
      };
      sliceState.cart = objToBeAdded;
      localStorage.setItem('cart', JSON.stringify(objToBeAdded));
    },
    ////////////////
    // reset cart
    ///////////////
    resetCartAction: (sliceState, action) => {
      sliceState.cart = {
        cartSupplierId: '',
        cartServiceId: '',
        cartServiceName: '',
        cartProducts: [],
        cartTotal: 0
      };
      sliceState.negotiationPrice = '';
      localStorage.removeItem('cart');
    },
    /////////////////////////////////////////////
    /////////////////////////////////////////////
    /////////////////////////////////////////////
    setNegotiationModalOpenedAction: (sliceState, action) => {
      sliceState.negotiationModalOpened = action.payload;
    },
    setNegotiationPriceAction: (sliceState, action) => {
      sliceState.negotiationPrice = action.payload;
    }
  },
  extraReducers: {
    // companies
    ///////////////////////
  }
});

const cartReducer = cartSlice.reducer;

export const {
  addProductToCartAction,
  incDecProductQty,
  removeCartProductAction,
  calcCartTotal,
  ///
  setNegotiationModalOpenedAction,
  setNegotiationPriceAction,
  resetCartAction
} = cartSlice.actions;

export default cartReducer;
