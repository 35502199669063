/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AdvancedSearchForm from './AdvancedSearchForm';
import queryString from 'query-string';
import './AdvancedSearchPage.scss';
import { useEffect } from 'react';
import TopRatedCard from '../../components/top-rated-card/TopRatedCard';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { getCompaniesAction } from '../../redux/companies-slice/companiesSliceThunkApis';
import { setCompaniesAction } from '../../redux/companies-slice/companiesSlice';
import { LoadingOutlined } from '@ant-design/icons';
import imgsSrcs from '../../helpers/imgsSrcs';

const AdvancedSearchPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingCompanies = useSelector(
    (store) => store.companies.isLoadingCompanies
  );
  const fetchedCompanies = useSelector(
    (store) => store.companies.fetchedCompanies
  );

  useEffect(() => {
    dispatch(
      getCompaniesAction({
        filterObj: {
          ...values
        }
      })
    );

    return () => {
      dispatch(setCompaniesAction([]));
    };
  }, [search]);

  const renderCompList = () => {
    return fetchedCompanies.map((item) => {
      return <TopRatedCard view="grid" key={item?.id} card={item} />;
    });
  };

  const renderContent = () => {
    if (isLoadingCompanies) {
      return (
        <div
          style={{
            height: 332,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (fetchedCompanies?.length === 0) {
      return (
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              width: '100%',
              maxWidth: '300px'
            }}
            src={imgsSrcs?.emptyResultsImg}
            alt="empty"
          />
        </div>
      );
    } else if (fetchedCompanies?.length > 0) {
      return <div className="advanced-search-data">{renderCompList()}</div>;
    }
    return null;
  };

  return (
    <div className="advanced-search-page">
      <div className="section">
        <div className="custom-container">
          <AdvancedSearchForm />
        </div>
      </div>

      <div className="custom-container">{renderContent()}</div>

      {isLoadingCompanies && <LoadingModal />}
    </div>
  );
};

export default AdvancedSearchPage;
