import { Button, Form, Modal } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';
import checkRes from '../../utils/checkRes';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import imgsSrcs from '../../helpers/imgsSrcs';
import FileInput from '../../common/file-input/FileInput';

const ProfileUploadImageModal = ({ modalOpened, setModalOpened }) => {
  const sharedT = (key) => t(`profileForm.${key}`);
  const user = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState(null);

  const schema = Yup.object().shape({});
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      image: ''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    setIsSubmitting(true);

    const formData = new FormData();
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }

    customApiRequest(
      updateProfileApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setModalOpened(false);
          if (user?.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data.data,
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          } else {
            dispatch(
              setUserToStateAction({
                ...res?.data.data,
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          }
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Modal
      className="shared-custom-modal profile-upload-image-modal"
      width="96%"
      style={{ maxWidth: '542px' }}
      title={sharedT('uploadImage')}
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <Form
        className="profile-settings-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div
            className="attatches-wrap"
            style={{
              marginBottom: 22
            }}
          >
            <FileInput
              files={files}
              setFiles={setFiles}
              name="offerFile"
              label=""
              max={5}
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              accept="image/*"
              multiple={false}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText={sharedT('drag')}
              className="product-images-dropzone"
              dropzoneUrls={user?.image ? [{ url: user.image }] : []}
              canDelete={false}
              icon={
                <img
                  src={imgsSrcs?.uploadCloudImg}
                  style={{ width: 22 }}
                  alt="upload"
                />
              }
              showError={errors?.offerFile}
              errorMsg={errors?.offerFile?.message}
            />
          </div>

          <div className="form-action-btns">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmitting}
            >
              {t('profileForm.saveChanges')}
            </Button>

            <button
              type="button"
              onClick={() => {
                setFiles(null);
                setModalOpened(false);
              }}
              className="cancel-btn"
            >
              {t('profileForm.cancel')}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ProfileUploadImageModal;
