/* eslint-disable eqeqeq */
import * as Yup from 'yup';

import quoteTypes from '../../const-values/quoteTypes';

const getQuoteStepOneValidationSchema = (quoteType, sharedErrTrans) => {
  if (quoteType === quoteTypes?.supplierQuote)
    return Yup.object().shape({
      companyType:
        quoteType === quoteTypes?.publicQuote
          ? Yup.string().required(sharedErrTrans('companyType', 'required'))
          : Yup.string().optional('test'),
      subject: Yup.string().required(sharedErrTrans('subject', 'required')),
      country_id: Yup.string().required(
        sharedErrTrans('country_id', 'required')
      ),
      city_id: Yup.string().required(sharedErrTrans('city_id', 'required'))
    });

  return Yup.object().shape({
    companyType:
      quoteType === quoteTypes?.publicQuote
        ? Yup.string().required(sharedErrTrans('companyType', 'required'))
        : Yup.string().optional('test'),
    subject: Yup.string().required(sharedErrTrans('subject', 'required')),
    service_id: Yup.string().required(sharedErrTrans('service', 'required')),
    country_id: Yup.string().required(sharedErrTrans('country_id', 'required')),
    city_id: Yup.string().required(sharedErrTrans('city_id', 'required'))
  });
};

export default getQuoteStepOneValidationSchema;
