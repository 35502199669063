/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { useEffect } from 'react';
import './ProfileSettingsForm.scss';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';

const ProfileSettingsForm = ({ setSettingsFormOpened }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { i18n, t } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    name: Yup.string().required(generalrequiredErrStr('name')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      country_id: '',
      city_id: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (user) {
      setValue('name', user?.name || '');
      setValue('email', user?.email || '');
      setValue('phone', user?.phone || '');
      setValue('country_id', user?.country?.id ? String(user.country.id) : '');
      setValue('city_id', user?.city?.id ? String(user.city.id) : '');
    }
  }, [user]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    setIsSubmitting(true);
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }

    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    if (data.phone)
      formData.append('phone', phone ? `${countryCode}${phone}` : data.phone);
    if (data.email) formData.append('email', data.email);
    if (data?.country_id) formData.append('country_id', data.country_id);
    if (data?.city_id) formData.append('city_id', data.city_id);

    customApiRequest(
      updateProfileApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setSettingsFormOpened(false);
          if (user?.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data.data,
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          } else {
            dispatch(
              setUserToStateAction({
                ...res?.data.data,
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          }
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="profile-settings-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name"
              type="text"
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('name')}</p>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="email"
              type="text"
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('email')}</p>
          </div>

          <div
            className={`country-code-phone-wrap ${
              watch('phone') ? 'focused' : ''
            } ${i18n.dir()}`}
          >
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    // placeholder={generalLabelStr('phone')}
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    countryCallingCodeEditable={false}
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="custom-label">{generalLabelStr('phone')}</p>
            <p className="error-p">{errors?.phone?.message}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            parentClassName="signup-form"
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}

          <div className="form-action-btns">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmitting}
            >
              {t('profileForm.saveChanges')}
            </Button>

            <button
              type="button"
              onClick={() => {
                reset({
                  name: user?.name || '',
                  phone: user?.phone || '',
                  email: user?.email || '',
                  country_id: user?.country?.id ? String(user.country.id) : '',
                  city_id: user?.city?.id ? String(user.city.id) : ''
                });
              }}
              className="cancel-btn"
            >
              {t('profileForm.cancel')}
            </button>
          </div>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default ProfileSettingsForm;
