import { t } from 'i18next';
import SubscriptionBox from './SubscriptionBox';
import './PricesPage.scss';

const PricesPage = () => {
  const sharedT = (key) => t(`pricesPage.${key}`);

  return (
    <div className="prices-page">
      <div className="custom-container">
        <h2>{sharedT('subscriptionPlans')}</h2>

        <div className="page-body">
          <div className="sub-boxs-wrapper">
            <SubscriptionBox />
            <SubscriptionBox />
            <SubscriptionBox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricesPage;
