/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useGeoLocation from '../../custom-hooks/useGeoLocation';
import slideImg from '../../assets/imgs/slide.jpg';
import { useSelector } from 'react-redux';

const INITIAL_VALUES = {
  allFetchedFilterCats: [],
  location: null,
  appCity: null,
  setAppCity: (v) => {},

  // appbar search stores
  isLoadingStores: false,
  setIsLoadingStores: (v) => {},
  allFetchedStores: [],
  setAllFetchedStores: (v) => {},
  fetchStoresCount: 0,
  setFetchStoresCount: (v) => {},

  // home data
  isLoadingHomeData: false,
  setIsLoadingHomeData: (v) => {},
  allFetchedHomeData: [],
  setAllFetchedHomeData: (v) => {},
  fetchHomeCount: 0,
  setFetchHomeCount: (v) => {},
  //
  isLoadingHomeSlider: false,
  setIsLoadingHomeSlider: (v) => {},
  fetchedHomeSlides: [
    {
      id: 1,
      image: slideImg,
      mainTitle: 'The Leading Construction Platform 1',
      subTitle:
        'Hire the right contractors, suppliers and engineering offices with our proposal and project management solutions',
      to: 'test'
    },
    {
      id: 2,
      image: slideImg,
      mainTitle: 'The Leading Construction Platform 2',
      subTitle:
        'Hire the right contractors, suppliers and engineering offices with our proposal and project management solutions',
      to: 'test'
    },
    {
      id: 3,
      image: slideImg,
      mainTitle: 'The Leading Construction Platform 3',
      subTitle:
        'Hire the right contractors, suppliers and engineering offices with our proposal and project management solutions',
      to: 'test'
    }
  ],
  setFetchedHomeSlides: (v) => {}
};

const SharedAppContext = createContext(INITIAL_VALUES);

export const SharedAppProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const user = useSelector((store) => store.user.user);
  const [appCity, setAppCity] = useState(INITIAL_VALUES?.appCity);
  const [isLoadingStores, setIsLoadingStores] = useState(
    INITIAL_VALUES?.isLoadingStores
  );
  const [allFetchedStores, setAllFetchedStores] = useState(
    INITIAL_VALUES?.allFetchedStores
  );
  const [fetchStoresCount, setFetchStoresCount] = useState(
    INITIAL_VALUES?.fetchStoresCount
  );

  const [isLoadingHomeData, setIsLoadingHomeData] = useState(
    INITIAL_VALUES.isLoadingHomeData
  );
  const [allFetchedHomeData, setAllFetchedHomeData] = useState(
    INITIAL_VALUES.allFetchedHomeData
  );
  const [fetchHomeCount, setFetchHomeCount] = useState(
    INITIAL_VALUES.fetchHomeCount
  );
  const [isLoadingHomeSlider, setIsLoadingHomeSlider] = useState(
    INITIAL_VALUES.isLoadingHomeSlider
  );
  const [fetchedHomeSlides, setFetchedHomeSlides] = useState(
    INITIAL_VALUES.fetchedHomeSlides
  );

  const location = useGeoLocation();

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage(i18n.language);
    Geocode.fromLatLng(
      location?.coordinates?.lat || 21.4858,
      location?.coordinates?.lng || 39.1925
    ).then(
      (res) => {
        if (
          res?.results?.length > 0 &&
          res.results[0]?.address_components?.length > 0 &&
          res?.results[0]?.address_components[4]
        ) {
          // console.log('results : ', res);
          setAppCity(res.results[0].address_components[4].short_name);
        }
      },
      (error) => {
        console.log('err : ', error);
      }
    );
  }, [location?.loaded, location?.coordinates, i18n.language]);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (location?.coordinates?.lat && location?.coordinates?.lng) {
  //     setIsLoadingStores(true);
  //     customApiRequest(
  //       getGeneralStoresApi(
  //         // {
  //         //   lat: location?.coordinates?.lat,
  //         //   lng: location?.coordinates?.lng
  //         // },
  //         {},
  //         user?.token,
  //         i18n.language
  //       ),
  //       (res) => {
  //         if (isMounted) {
  //           setIsLoadingStores(false);

  //           if (checkRes(res) && res?.data?.data?.length > 0) {
  //             setAllFetchedStores(res.data.data);
  //             setFetchStoresCount((prev) => prev + 1);
  //           } else {
  //             setAllFetchedStores([]);
  //           }
  //         }
  //       },
  //       (error) => {
  //         setIsLoadingStores(false);
  //       }
  //     );
  //   }

  //   return () => (isMounted = false);
  // }, [location?.coordinates?.lat, location?.coordinates?.lng]);

  // useEffect(() => {
  //   let isMounted = true;
  //   setIsLoadingHomeSlider(true);
  //   customApiRequest(
  //     getHomeSlidesApi(i18n.language),
  //     (res) => {
  //       if (isMounted) {
  //         setIsLoadingHomeSlider(false);
  //         if (checkRes(res) && res.data?.data) {
  //           const data = res.data.data;
  //           setFetchedHomeSlides(
  //             data.map((d) => ({
  //               ...d,
  //               mainTitle: d?.title,
  //               subTitle: d?.desc,
  //               to: d?.btnLink
  //             }))
  //           );
  //         }
  //       }
  //     },
  //     (error) => {
  //       setIsLoadingHomeSlider(false);
  //     }
  //   );

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [i18n.language]);

  return (
    <SharedAppContext.Provider
      value={{
        appCity,
        setAppCity,
        location,
        //
        isLoadingStores,
        setIsLoadingStores,
        allFetchedStores,
        setAllFetchedStores,
        fetchStoresCount,
        setFetchStoresCount,
        // home
        isLoadingHomeData,
        setIsLoadingHomeData,
        allFetchedHomeData,
        setAllFetchedHomeData,
        fetchHomeCount,
        setFetchHomeCount,
        //
        isLoadingHomeSlider,
        setIsLoadingHomeSlider,
        fetchedHomeSlides,
        setFetchedHomeSlides
      }}
    >
      {children}
    </SharedAppContext.Provider>
  );
};

export default SharedAppContext;
