import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signupAction } from '../../redux/user-slice/userSliceThunkApis';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';
import routerLinks from '../../components/app/routerLinks';

const useSignupEmailPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = new FormData();
  const signMeUpWithEmailPassword = async (data) => {
    if (data.userType) formData.append('userType', data.userType);
    if (data.name) formData.append('name', data.name);
    if (data.managerName) formData.append('name', data.managerName);
    if (data.companyName) formData.append('companyName', data.companyName);
    if (data.phone)
      formData.append('phone', `${data.countryCode}${data.phone}`);
    if (data.email) formData.append('email', data.email);
    if (data.password) formData.append('password', data.password);
    if (data?.country_id) formData.append('country_id', data.country_id);
    if (data?.city_id) formData.append('city_id', data.city_id);
    if (data?.companyType_id)
      formData.append('companyType_id', data.companyType_id);
    if (data?.subServices?.length > 0) {
      for (let item of data.subServices) formData.append('services[]', item);
    }
    try {
      const action = await dispatch(
        signupAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;

      if (res?.data?.data && res?.data?.status === 1) {
        if (res?.data?.data?.status === 0) {
          dispatch(
            setUserToStateAction({
              ...res?.data.data,
              accountType: Number(data?.accountType),
              companyType: Number(data?.companyType_id),
              activated: false,
              firstTime: false,
              remember: data?.remember || false
            })
          );
          navigate(routerLinks?.activateAccountRoute);
        } else if (res?.data?.data?.status === 1) {
          if (data.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data?.data,
                accountType: Number(data?.accountType),
                companyType: Number(data?.companyType_id),
                activated: true,
                firstTime: false,
                remember: data?.remember || false
              })
            );
            navigate(routerLinks?.homePage);
          } else {
            dispatch(
              setUserToStateAction({
                ...res.data.data,
                accountType: Number(data?.accountType),
                companyType: Number(data?.companyType_id),
                activated: true,
                firstTime: false,
                remember: data?.remember || false
              })
            );
            // if (
            //   data?.accountType == accountTypes()?.user ||
            //   res?.data?.data?.type == accountTypes()?.user
            // ) {
            //   navigate(routerLinks?.homePage);
            // } else {
            //   navigate(merchentRouterLinks?.merchentDashboard);
            // }
          }
        }
      }
    } catch (error) {}
  };

  return { signMeUpWithEmailPassword };
};

export default useSignupEmailPassword;
