const generalRouterLinks = {
  aboutUsRoute: '/about-us',
  contactUsRoute: '/contact-us',
  pricesRoute: '/prices',
  publicQuotesRoute: '/public-quotes',
  singlePublicQuoteRoute: (order_id) =>
    order_id
      ? `/public-quotes/single/${order_id}`
      : '/public-quotes/single/:order_id',
  //
  //
  //
  //
  advancedSearch: '/advanced-search',
  suppliersRoute: '/companies/suppliers',
  contractorsRoute: '/companies/contractors',
  engineeringOfficesRoute: '/companies/engineering-offices',
  supportiveServicesRoute: '/companies/supportive-services',
  singleCompanyRoute: '/companies/details',
  supplierProductsRoute: (company_id, service_id) =>
    company_id
      ? `/companies/details/${company_id}/services/${service_id}/products`
      : '/companies/details/:company_id/services/:service_id/products',
  userCartRoute: (company_id, service_id) =>
    company_id && service_id
      ? `/cart/company/${company_id}/service/${service_id}`
      : '/cart/company/:company_id/service/:service_id',
  //
  //
  //
  //
  //
  //
  //
  generalProductsRoute: '/products',
  generalSingleProductRoute: (productId) => {
    if (productId) return `/products/${productId}`;
    return '/products/:productId';
  },
  generalAffiliateProductRoute: (productId, agentKey) => {
    if (productId && agentKey) return `/products/${productId}/${agentKey}`;
    return '/products/:productId/:agentKey';
  },
  blogsRoute: '/blogs',
  singleBlogRoute: (blogId) => {
    if (blogId) return `/blogs/${blogId}`;
    return '/blogs/:blogId';
  },
  refundTermsRoute: '/refund-terms'
};

export default generalRouterLinks;
