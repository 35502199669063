/* eslint-disable eqeqeq */
import { Avatar, Modal } from 'antd';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CartIcon from '../../common/icons/CartIcon';
import HeartIcon from '../../common/icons/HeartIcon';
import SearchIcon from '../../common/icons/SearchIcon';
import { UserOutlined } from '@ant-design/icons';
import './FixedBottomHeaderMd.scss';
import routerLinks from '../app/routerLinks';
import accountTypes from '../../const-values/accountTypes';
import { useTranslation } from 'react-i18next';
import MainAppBarSearch from './MainAppBarSearch';
import useGeoLocation from '../../custom-hooks/useGeoLocation';
import AppNotificationsDropdownMenu from './AppNotificationsDropdownMenu';
import { useSelector } from 'react-redux';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';

const SearchModal = ({ modalOpened, setModalOpened }) => {
  const { i18n } = useTranslation();
  const location = useGeoLocation();

  return (
    <Modal
      wrapClassName="bottom-header-modal-wrapper"
      transitionName=""
      className={`bottom-header-modal shared-custom-modal ${i18n.dir()}`}
      width="100%"
      style={{ maxWidth: '694px' }}
      title=""
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <MainAppBarSearch location={location} />
    </Modal>
  );
};

const FixedBottomHeaderMd = () => {
  const [searchModalOpened, setSearchModalOpened] = useState(false);
  const user = useSelector((store) => store.user.user);
  const getProfileLink = () => {
    if (user) {
      if (user?.accountType == accountTypes()?.user) {
        return routerLinks?.userDashboard;
      } else if (
        user?.accountType == accountTypes()?.company ||
        user?.accountType == accountTypes()?.employee
      )
        return routerLinks?.merchentDashboard;
    }
    return routerLinks?.signinPage;
  };
  return (
    <>
      <div className="fixed-bottom-header">
        <div className="custom-container">
          <div className="links-list">
            <button
              onClick={() => {
                setSearchModalOpened(true);
              }}
              className="shared-nav-btn search-btn"
            >
              <SearchIcon color="#999" />
            </button>
            <RouterLink
              className="shared-nav-btn fav-btn"
              to={routerLinks?.myFavRoute}
            >
              <HeartIcon />
            </RouterLink>

            <div className="profile-placeholder"></div>
            <RouterLink
              to={getProfileLink()}
              className="profile-menu-btn"
              type="text"
            >
              <Avatar size={64} icon={<UserOutlined />} src={user?.image} />
            </RouterLink>

            {/* <RouterLink
              className="shared-nav-btn"
              to={generalRouterLinks?.userCartRoute}
            >
              <CartIcon />
            </RouterLink> */}
            <div className="shared-nav-btn">
              <AppNotificationsDropdownMenu parentClassName="fixed-bottom-header" />
            </div>
          </div>
        </div>
      </div>

      {searchModalOpened && (
        <SearchModal
          modalOpened={searchModalOpened}
          setModalOpened={setSearchModalOpened}
        />
      )}
    </>
  );
};

export default FixedBottomHeaderMd;
