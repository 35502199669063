/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import masterCardImg from '../../assets/imgs/icons/master-card.svg';
import visaImg from '../../assets/imgs/icons/visa.svg';
import madaImg from '../../assets/imgs/icons/mada.svg';
import m3roofImg from '../../assets/imgs/icons/m3roof-img.png';
import { Link as RouterLink } from 'react-router-dom';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import { useTranslation } from 'react-i18next';
import googlePlayImg from '../../assets/imgs/icons/google-play-white.png';
import appStoreImg from '../../assets/imgs/icons/app-store-white.png';
import accountTypes from '../../const-values/accountTypes';
import footerFrameImg from '../../assets/imgs/footer-frame.svg';
import './MainAppFooter.scss';
import Logo from '../../common/logo/Logo';
import fbImg from '../../assets/imgs/icons/social-icons/facebook-img.png';
import twitterImg from '../../assets/imgs/icons/social-icons/twitter-img.png';
import instaImg from '../../assets/imgs/icons/social-icons/instagram-img.png';
import linkedinImg from '../../assets/imgs/icons/social-icons/linkedin-img.png';
import { useSelector } from 'react-redux';

const MainAppFooter = () => {
  const user = useSelector((store) => store.user.user);
  const { t } = useTranslation();

  return (
    !user?.firstTime && (
      <footer
        style={{
          backgroundImage: `url(${footerFrameImg})`
        }}
        className="main-app-footer"
      >
        {(true || user?.accountType == accountTypes()?.user || !user) && (
          <div className="custom-container">
            <div className="main-content">
              <div className="logo-about-wrapper">
                <Logo className="footer-logo" colored />

                <p className="about-text">{t('footer.aboutText')}</p>
              </div>
              <div className="list-wrap useful-links-list">
                <div className="list-title">{t('footer.usefulLinks')}</div>
                <div className="list-itself">
                  <RouterLink to="/">{t('footer.about')}</RouterLink>
                  <RouterLink to="/">{t('footer.terms')}</RouterLink>
                  <RouterLink to="/">{t('footer.privacy')}</RouterLink>
                  <RouterLink to={generalRouterLinks?.refundTermsRoute}>
                    {t('footer.refundTerms')}
                  </RouterLink>
                </div>
              </div>
              <div className="list-wrap useful-links-list">
                <div className="list-title">{t('footer.support')}</div>
                <div className="list-itself">
                  <RouterLink to={generalRouterLinks?.blogsRoute}>
                    {t('footer.help')}
                  </RouterLink>
                  <RouterLink to="/">{t('footer.terms')}</RouterLink>
                  <RouterLink to="/">{t('footer.privacy')}</RouterLink>
                  <RouterLink to="/">{t('footer.contact')}</RouterLink>
                </div>
              </div>
              <div className="contact-us-list">
                <div className="list-title">{t('footer.installApp')}</div>

                <div className="stores-links">
                  <div className="links">
                    <a
                      href="http://google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={googlePlayImg} alt="" />
                    </a>
                    <a
                      href="http://google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appStoreImg} alt="" />
                    </a>
                  </div>
                </div>

                <div className="m3roof-link">
                  <img src={m3roofImg} alt="m3roof" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-bottom-content">
          <div className="custom-container">
            {/* <Logo className="footer-logo" /> */}
            <div className="content">
              <p>{t('copyRights')}</p>
              <ul className="footer-payment-methods">
                <li>
                  <img src={fbImg} alt="mada" />
                </li>
                <li>
                  <img src={twitterImg} alt="visa" />
                </li>
                <li>
                  <img src={instaImg} alt="master-card" />
                </li>
                <li>
                  <img src={linkedinImg} alt="master-card" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default MainAppFooter;
