import { CheckOutlined } from '@ant-design/icons';
import { t } from 'i18next';

const SubscriptionBox = () => {
  const sharedT = (key) => t(`pricesPage.${key}`);
  return (
    <div className="subscription-box">
      <div className="box-header">
        <p className="basic-p">Basic</p>
        <div className="price-wrap">
          <span className="price-span">$15 - </span>

          <div className="stirked-price">$200</div>

          <span className="per-span">Per month</span>
        </div>
      </div>

      <div className="box-body">
        <div className="features-list">
          <p className="list-p">
            <span className="check-icon-wrap">
              <CheckOutlined />
            </span>
            Unlimited Requests all of the month
          </p>
          <p className="list-p">
            <span className="check-icon-wrap">
              <CheckOutlined />
            </span>
            Negotiate With offers Prices
          </p>
          <p className="list-p">
            <span className="check-icon-wrap">
              <CheckOutlined />
            </span>
            Send and receive attachements
          </p>
          <p className="list-p">
            <span className="check-icon-wrap">
              <CheckOutlined />
            </span>
            Send request to group of company
          </p>
        </div>
      </div>

      <div className="box-footer">
        <button className="get-started-btn">{sharedT('getStarted')}</button>
      </div>
    </div>
  );
};

export default SubscriptionBox;
