/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './QuoteStepTwo.scss';
import { useDispatch, useSelector } from 'react-redux';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useEffect } from 'react';
import quoteTypes from '../../const-values/quoteTypes';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import imgsSrcs from '../../helpers/imgsSrcs';
import FileInput from '../../common/file-input/FileInput';
import { removeCartProductAction } from '../../redux/cart-slice/cartSlice';
import { getCompanyTypesArr } from '../../const-values/accountTypes';

const QuoteStepTwo = () => {
  const [files, setFiles] = React.useState(null);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const generalLabelStr = (key) => t(`requestQuoteForm.${key}.label`);

  // redux
  const quoteType = useSelector((store) => store.quote.quoteType);
  // const companyTypes = useSelector(
  //   (store) => store.general.fetchedCompanyTypesData
  // );
  const {
    watch,
    formState: { errors },
    register,
    unregister,
    setValue,
    control
  } = useFormContext();

  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });

  const units = useSelector((store) => store.general.fetchedUnitsData);
  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);

  const isPublicQuote = quoteType === quoteTypes?.publicQuote;
  const isSharedSuppQuoteType =
    quoteType === quoteTypes?.supplierQuote ||
    quoteType === quoteTypes?.supplierQuoteWithNegotiation;
  const renderPublicContractorProudctFields = () => {
    return (
      productsFields?.length > 0 &&
      productsFields.map((item, index) => {
        return (
          <div
            className="product-fields-wrapper public-contractor"
            key={item?.id}
          >
            <div className="fields-itself">
              <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                <AntdTextField
                  disabled={isSharedSuppQuoteType}
                  className="custom-text-field"
                  name={`products.${index}.productName`}
                  type="text"
                  errorMsg={
                    errors?.products &&
                    errors.products[index]?.productName &&
                    errors.products[index].productName.message
                  }
                  validateStatus={
                    errors?.products &&
                    errors.products[index]?.productName &&
                    errors?.products[index]?.productName
                      ? 'error'
                      : ''
                  }
                  control={control}
                />
                <p className="custom-label">{generalLabelStr('productName')}</p>
              </div>

              <div
                className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
              >
                <AntdSelectOption
                  className="custom-select-field"
                  name={`products.${index}.unit_id`}
                  errorMsg={
                    errors?.products &&
                    errors.products[index]?.unit_id &&
                    errors.products[index].unit_id.message
                  }
                  validateStatus={
                    errors?.products &&
                    errors.products[index]?.unit_id &&
                    errors?.products[index]?.unit_id
                      ? 'error'
                      : ''
                  }
                  control={control}
                  setValue={setValue}
                  options={
                    units?.length > 0 &&
                    units.map((obj) => ({
                      title: obj?.name,
                      value: obj?.id
                    }))
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="quote-step-one"
                />
                <p className="custom-label">{generalLabelStr('unit')}</p>
              </div>

              <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                <AntdTextField
                  className="custom-text-field"
                  name={`products.${index}.quantity`}
                  type="text"
                  errorMsg={
                    errors?.products &&
                    errors.products[index]?.quantity &&
                    errors.products[index].quantity.message
                  }
                  validateStatus={
                    errors?.products &&
                    errors.products[index]?.quantity &&
                    errors?.products[index]?.quantity
                      ? 'error'
                      : ''
                  }
                  control={control}
                />
                <p className="custom-label">{generalLabelStr('quantity')}</p>
              </div>
            </div>

            <button
              className="delete-product-btn"
              type="button"
              onClick={() => {
                removeProductField(index);
              }}
            >
              <img src={imgsSrcs.deleteBoxImg} alt="delete" />
            </button>
          </div>
        );
      })
    );
  };

  const renderSharedSuppProducts = () => {
    if (cartProducts?.length > 0) {
      return (
        <div className="cart-fields-list">
          {cartProducts.map((item, index) => (
            <div key={index} className="cart-field-delete-btn-wrap">
              <div className="item-fields">
                <div className="row-field name-field">
                  <div className="field-label">
                    {generalLabelStr('productName')}
                  </div>
                  <div className="field-value">{item?.name}</div>
                </div>
                <div className="row-field qty-field">
                  <div className="field-label">
                    {generalLabelStr('quantity')}
                  </div>
                  <div className="field-value">{item?.quantity}</div>
                </div>
                <div className="row-field unit-field">
                  <div className="field-label">{generalLabelStr('unit')}</div>
                  <div className="field-value">{item?.unit?.name}</div>
                </div>
                <div className="row-field option-field">
                  <div className="field-label">{generalLabelStr('option')}</div>
                  <div className="field-value">{item?.option?.name}</div>
                </div>
                <div className="row-field price-field">
                  <div className="field-label">{generalLabelStr('price')}</div>
                  <div className="field-label">
                    {(item?.quantity * item?.price).toFixed(2)}
                  </div>
                </div>
              </div>

              <button
                className="delete-product-btn"
                type="button"
                onClick={() => {
                  dispatch(
                    removeCartProductAction({
                      product_id: item?.product_id,
                      option_id: item?.option?.id
                    })
                  );
                }}
              >
                <img src={imgsSrcs.deleteBoxImg} alt="delete" />
              </button>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="stepper-step quote-step-two">
      {!isSharedSuppQuoteType && (
        <div className="add-btn-row">
          <button
            className="add-product-btn"
            type="button"
            style={{
              cursor: !errors?.products ? 'pointer' : 'default',
              opacity: !errors?.products ? '1' : '0.5'
            }}
            onClick={() => {
              if (!errors?.products)
                appendProductField({
                  productName: '',
                  quantity: '',
                  unit_id: ''
                });
            }}
          >
            <img src={imgsSrcs.plusImg} alt="add product" />
            {t('requestQuoteForm.addBtn')}
          </button>
        </div>
      )}

      {isSharedSuppQuoteType
        ? renderSharedSuppProducts()
        : renderPublicContractorProudctFields()}

      <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
        <AntdSelectOption
          className="custom-select-field"
          name={`endUserType`}
          errorMsg={errors?.endUserType && errors.endUserType.message}
          validateStatus={errors?.endUserType ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={
            getCompanyTypesArr(t)?.length > 0 &&
            getCompanyTypesArr(t).map((obj) => ({
              title: obj?.name,
              value: obj?.id
            }))
          }
          showSearch={true}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
        <p className="custom-label">{generalLabelStr('endUserType')}</p>
      </div>

      <div
        className="attatches-wrap"
        style={{
          marginBottom: 22
        }}
      >
        <FileInput
          files={files}
          setFiles={setFiles}
          name="orderFiles"
          label=""
          max={5}
          // accept="image/png, image/jpg, image/jpeg, image/gif"
          accept="image/*"
          multiple={true}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          // setUrls={setUrls}
          dropzoneText={generalLabelStr('attatchments')}
          className="product-images-dropzone"
          dropzoneUrls={[]}
          canDelete={false}
          icon={
            <img
              src={imgsSrcs?.uploadCloudImg}
              style={{ width: 22 }}
              alt="upload"
            />
          }
          // disabled={
          //   fetchedSingleProductDetails?.productImages?.length >= 5 &&
          //   watch('productImages')?.length >= 1
          //     ? true
          //     : false
          // }
          showError={errors?.orderFiles}
          errorMsg={errors?.orderFiles?.message}
        />
      </div>
    </div>
  );
};

export default QuoteStepTwo;
