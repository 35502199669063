import { t } from 'i18next';
import { useSelector } from 'react-redux';
import apisUrls from '../../apis/apisUrls';
import CustomImage from '../../common/custom-image/CustomImage';
import {
  threeOrderStatusArr,
  threeOrderStatusValues
} from '../../const-values/orderStatus';
import securePaymentImg from '../../assets/imgs/secure-payment.png';

const OrderSideCard = () => {
  const sharedT = (key) => t(`ordersPage.${key}`);

  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );

  const renderOrderStatus = () => {
    const found = threeOrderStatusArr(t).find((obj) => {
      return obj?.value === fetchedUserSingleOrder?.status;
    });
    if (found) {
      return (
        <div
          className={`order-status-tag ${
            !fetchedUserSingleOrder?.status
              ? 'all'
              : `_${fetchedUserSingleOrder.status}`
          }`}
        >
          {found?.name}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="side-card-wrapper">
      <div className="side-card card-with-header">
        <div className="card-header">{sharedT('orderCompInfo')}</div>

        <div className="id-status">
          <div className="id-wrap">
            <p className="id-label">{sharedT('orderId')}</p>
            <p className="id-value">#{fetchedUserSingleOrder.id}</p>
          </div>

          <div className="order-status">{renderOrderStatus()}</div>
        </div>

        {fetchedUserSingleOrder?.company && (
          <div className="comp-info">
            <div className="name-location">
              <p className="comp-name">
                {fetchedUserSingleOrder?.company?.name || '---'}
              </p>
              {fetchedUserSingleOrder?.company?.country && (
                <p className="comp-location">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9999 12.3089C8.2249 12.3089 6.7749 10.8672 6.7749 9.08385C6.7749 7.30052 8.2249 5.86719 9.9999 5.86719C11.7749 5.86719 13.2249 7.30885 13.2249 9.09219C13.2249 10.8755 11.7749 12.3089 9.9999 12.3089ZM9.9999 7.11719C8.91657 7.11719 8.0249 8.00052 8.0249 9.09219C8.0249 10.1839 8.90824 11.0672 9.9999 11.0672C11.0916 11.0672 11.9749 10.1839 11.9749 9.09219C11.9749 8.00052 11.0832 7.11719 9.9999 7.11719Z"
                      fill="#246DB4"
                    />
                    <path
                      d="M9.9999 19.466C8.76657 19.466 7.5249 18.9993 6.55824 18.0744C4.0999 15.7077 1.38324 11.9327 2.40824 7.44102C3.33324 3.36602 6.89157 1.54102 9.9999 1.54102C9.9999 1.54102 9.9999 1.54102 10.0082 1.54102C13.1166 1.54102 16.6749 3.36602 17.5999 7.44935C18.6166 11.941 15.8999 15.7077 13.4416 18.0744C12.4749 18.9993 11.2332 19.466 9.9999 19.466ZM9.9999 2.79102C7.5749 2.79102 4.45824 4.08268 3.63324 7.71602C2.73324 11.641 5.1999 15.0243 7.43324 17.166C8.8749 18.5577 11.1332 18.5577 12.5749 17.166C14.7999 15.0243 17.2666 11.641 16.3832 7.71602C15.5499 4.08268 12.4249 2.79102 9.9999 2.79102Z"
                      fill="#58B030"
                    />
                  </svg>
                  {fetchedUserSingleOrder?.company?.country?.name &&
                    `${fetchedUserSingleOrder.company.country.name}`}
                  {fetchedUserSingleOrder?.company?.city?.name &&
                    ` / ${fetchedUserSingleOrder.company.city.name}`}
                </p>
              )}
            </div>
            <CustomImage
              className="comp-img"
              src={fetchedUserSingleOrder?.company?.logo}
            />
          </div>
        )}
      </div>
      {fetchedUserSingleOrder?.status ===
        threeOrderStatusValues?.canceledByUser && (
        <div className="canceled-label">{sharedT('orderCanceled')}</div>
      )}
      {fetchedUserSingleOrder?.status === threeOrderStatusValues?.accepted && (
        <div className="accepted-label">{sharedT('orderAccepted')}</div>
      )}
      {fetchedUserSingleOrder?.status === threeOrderStatusValues?.rejected && (
        <div className="rejected-label">{sharedT('orderRejected')}</div>
      )}

      {fetchedUserSingleOrder?.status === threeOrderStatusValues?.accepted && (
        <a
          target="_blank"
          className="pay-btn"
          href={apisUrls.paymentUrl({
            order_id: fetchedUserSingleOrder?.id
          })}
          rel="noreferrer"
        >
          {t('ordersPage.payNow')}
          <img src={securePaymentImg} alt="secure payment" />
        </a>
      )}

      {fetchedUserSingleOrder?.status === threeOrderStatusValues?.completed && (
        <div className="accepted-label">{sharedT('orderCompleted')}</div>
      )}
    </div>
  );
};

export default OrderSideCard;
