import HomeDevlopmentSection from '../home-page/HomeDevlopmentSection';
import WorksSection from '../home-page/WorksSection';

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <HomeDevlopmentSection />
      <WorksSection />
    </div>
  );
};

export default AboutUsPage;
