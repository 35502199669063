/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import './AdvancedSearchForm.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { getCompanyTypesArr } from '../../const-values/accountTypes';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';
// import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';

const AdvancedSearchForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  const generalLabelStr = (key) => t(`signup_form.${key}.label`);
  // const fcmDeviceToken = useFCMDeviceToken();
  const schema = Yup.object().shape({
    companyType: Yup.string().required('')
  });
  const { search } = useLocation();
  const values = queryString.parse(search);
  const dispatch = useDispatch();
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );
  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      companyType: values?.companyType || '',
      service_id: values?.service_id || '',
      country_id: values?.country_id || '',
      city_id: values?.city_id || ''
    }
  });

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('service_id') && services?.length > 0) {
      let foundServObj = null;
      const arr = [];

      foundServObj = services.find((obj) => obj?.id == watch('service_id'));
      if (
        foundServObj &&
        typeof foundServObj === 'object' &&
        foundServObj?.subServices?.length >= 0
      ) {
        arr.push(...foundServObj.subServices);
      }

      setAllSubServicesArr(arr);
      setValue('subService_id', []);
    }
  }, [watch('service_id')]);

  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const onSubmit = async (data) => {
    navigate(
      `${generalRouterLinks.advancedSearch}?companyType=${
        data.companyType || ''
      }&service_id=${data.subService_id || ''}&country_id=${
        data.country_id || ''
      }&city_id=${data?.city_id || ''}&page=1`
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="advanced-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">
            {t('hero_section.advancedSearch')}
          </p>
          <p className="sub-title">{t('hero_section.subTitle')}</p>
        </div>

        <div className="form-body">
          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name={`companyType`}
              errorMsg={errors?.companyType && errors.companyType.message}
              validateStatus={errors?.companyType ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                getCompanyTypesArr(t)?.length > 0 &&
                getCompanyTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{generalLabelStr('companyType_id')}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="service_id"
            label={generalLabelStr('services')}
            mode="tag"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
            parentClassName="advanced-form"
          />
          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subService_id"
            label={generalLabelStr('subServices')}
            // mode="multiple"
            parentClassName="advanced-form"
            selectOptionArr={allSubServicesArr}
          />

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {t('hero_section.search')}
          </Button>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default AdvancedSearchForm;
