import { createAsyncThunk } from '@reduxjs/toolkit';
import addCompanyToFavApi from '../../apis/companies-apis/addCompanyToFavApi';
import getAllCompaniesApi from '../../apis/companies-apis/getAllCompaniesApi';
import getSingleCompanyApi from '../../apis/companies-apis/getSingleCompanyApi';
import getSuppliersProductsApi from '../../apis/companies-apis/getSupplierProductsApi';
import customApiRequest from '../../helpers/customApiRequest';

// companies
export const getCompaniesAction = createAsyncThunk(
  'general/getCompaniesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllCompaniesApi(filterObj),
      thunkAPI
    });
  }
);

// single company
export const getSingleCompanyAction = createAsyncThunk(
  'general/getSingleCompanyAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleCompanyApi(filterObj),
      thunkAPI
    });
  }
);

// supplier products
export const getSupplierProductsAction = createAsyncThunk(
  'general/getSupplierProductsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSuppliersProductsApi(filterObj),
      thunkAPI
    });
  }
);

// add company to fav
export const addCompanyToFavAction = createAsyncThunk(
  'general/addCompanyToFavAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await addCompanyToFavApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
