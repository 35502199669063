/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import { removeCurrentUserAction } from '../../redux/user-slice/userSlice';
import './ActivationsCodeForm.scss';

const ActivationCodeForm = ({
  value,
  digits,
  err,
  setErr,
  submitCodeCount,
  onChange,
  isLoadingState,
  submitForm,
  resendCode,
  btnTypes
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sharedT = (key) => t(`activateCodeForm.${key}`);
  React.useEffect(() => {
    if (value?.trim().length === 4) {
      setErr({});
    }
  }, [value]);
  React.useEffect(() => {
    return () => {
      onChange('');
    };
  }, []);

  const onSubmit = () => {
    submitForm();
  };
  const onResendCode = (e) => {
    e.preventDefault();
    resendCode();
  };
  return (
    <form className="digits-form">
      <div className="digits-wrap">
        <input inputMode="decimal" autoFocus {...digits[0]} />
        <input inputMode="decimal" {...digits[1]} />
        <input inputMode="decimal" {...digits[2]} />
        <input inputMode="decimal" {...digits[3]} />
      </div>
      {err && (
        <p style={{ color: 'red', textAlign: 'center', marginTop: 8 }}>
          {err.message}
        </p>
      )}

      <div className="dont-btn-wrap">
        <span>{sharedT('dont')}</span>
        <button
          type="button"
          // loading={
          //   isLoadingState?.type === btnTypes.resendCode &&
          //   isLoadingState?.isLoading
          // }
          className="resend-btn"
          onClick={onResendCode}
        >
          {sharedT('resend')}
        </button>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 22
        }}
      >
        <Button
          loading={
            isLoadingState?.type === btnTypes.confirmCode &&
            isLoadingState?.isLoading
          }
          className="submit-btn"
          onClick={onSubmit}
        >
          {sharedT('send')}
        </Button>
        {/* {submitCodeCount > 0 && (
          <Button
            loading={
              isLoadingState?.type === btnTypes.resendCode &&
              isLoadingState?.isLoading
            }
            className="submit-btn"
            onClick={onResendCode}
          >
            {sharedT('resend')}
          </Button>
        )} */}

        {/* {submitCodeCount > 0 && (
          <ButtonWithLoading
            isSubmitting={
              isLoadingState?.type === btnTypes.resendCode &&
              isLoadingState?.isLoading
            }
            errors={err}
            btnText="إعادة الارسال"
            loadingMsg="Please Wait.."
            className="submit-btn"
            form="activattion-code-form"
            onClick={resendCode}
          />
        )} */}
      </div>

      <RouterLink
        className="different-account"
        to={routerLinks?.signinPage}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(removeCurrentUserAction());
        }}
      >
        {t('activateCodeForm.differentAccount')}
      </RouterLink>
    </form>
  );
};

export default ActivationCodeForm;
