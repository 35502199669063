import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../common/icons/AddIcon';
import PlusIcon from '../../common/icons/PlusIcon';
import './FaqSection.scss';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const FaqSection = () => {
  const { t } = useTranslation();
  return (
    <section className="faq-section">
      <div className="custom-container">
        <div className="sec-title">{t('faq.title')}</div>
        <div className="sec-sub-title">{t('faq.sub')}</div>

        <div className="sec-content">
          <Collapse
            className="sec-collapse"
            // accordion
            bordered={false}
            expandIconPosition="end"
            expandIcon={() => <AddIcon />}
          >
            <Panel header="This is panel header 1" key="1">
              <p>{text}</p>
            </Panel>
            <Panel header="This is panel header 2" key="2">
              <p>{text}</p>
            </Panel>
            <Panel header="This is panel header 3" key="3">
              <p>{text}</p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
