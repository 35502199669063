/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './QuoteStepOne.scss';
import { useDispatch, useSelector } from 'react-redux';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import quoteTypes from '../../const-values/quoteTypes';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { getCompanyTypesArr } from '../../const-values/accountTypes';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';

const QuoteStepOne = () => {
  const {
    watch,
    formState: { errors },
    setValue,
    control
  } = useFormContext();
  const { i18n, t } = useTranslation();
  const generalLabelStr = (key) => t(`requestQuoteForm.${key}.label`);

  // redux
  const dispatch = useDispatch();
  const quoteType = useSelector((store) => store.quote.quoteType);
  const quoteModalOpened = useSelector((store) => store.quote.quoteModalOpened);
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );
  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('service_id') && services?.length > 0) {
      let foundServObj = null;
      const arr = [];

      foundServObj = services.find((obj) => obj?.id == watch('service_id'));
      if (
        foundServObj &&
        typeof foundServObj === 'object' &&
        foundServObj?.subServices?.length >= 0
      ) {
        arr.push(...foundServObj.subServices);
      }

      setAllSubServicesArr(arr);
      setValue('subService_id', []);
    }
  }, [watch('service_id')]);

  const countries = useSelector((store) => store.general.fetchedCountriesData);
  // const companyTypes = useSelector(
  //   (store) => store.general.fetchedCompanyTypesData
  // );

  const isPublicQuote = quoteType === quoteTypes?.publicQuote;

  return (
    <div className="stepper-step quote-step-one">
      <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
        <AntdTextField
          className="custom-text-field"
          name="subject"
          type="text"
          errorMsg={errors?.subject?.message}
          validateStatus={errors?.subject ? 'error' : ''}
          control={control}
        />
        <p className="custom-label">{generalLabelStr('subject')}</p>
      </div>

      {isPublicQuote && (
        <div
          className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
        >
          <AntdSelectOption
            className="custom-select-field"
            name={`companyType`}
            errorMsg={errors?.companyType && errors.companyType.message}
            validateStatus={errors?.companyType ? 'error' : ''}
            control={control}
            setValue={setValue}
            options={
              getCompanyTypesArr(t)?.length > 0 &&
              getCompanyTypesArr(t).map((obj) => ({
                title: obj?.name,
                value: obj?.id
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // formClassName="quote-step-one"
          />
          <p className="custom-label">{generalLabelStr('companyType')}</p>
        </div>
      )}

      {quoteType !== quoteTypes?.supplierQuote && quoteModalOpened && (
        <>
          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="service_id"
            label={generalLabelStr('service')}
            // mode="multiple"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
            parentClassName="quote-step-one"
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subService_id"
            label={generalLabelStr('subService')}
            // mode="multiple"
            parentClassName="quote-step-one"
            selectOptionArr={allSubServicesArr}
          />
        </>
      )}

      <CountryCityOptions
        countries={countries}
        countryName="country_id"
        cityName="city_id"
        countryLabel={generalLabelStr('country_id')}
        cityLabel={generalLabelStr('city_id')}
        //
        watch={watch}
        setValue={setValue}
        control={control}
        errors={errors}
      />

      <div className={`custom-text-area-wrapper ${i18n.dir()}`}>
        <AntdTextarea
          rows={6}
          className="custom-text-area"
          name="description"
          errorMsg={errors?.description?.message}
          validateStatus={errors?.description ? 'error' : ''}
          control={control}
        />
        <p className="custom-label">{generalLabelStr('description')}</p>
      </div>
    </div>
  );
};

export default QuoteStepOne;
