/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link as RouterLink, useLocation } from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../common/logo/Logo';
import mainAppBarLinks from './mainAppBarLinks';
import routerLinks from '../app/routerLinks';
import MainAppProfileMenu from './MainAppProfileMenu';
import { Dropdown } from 'antd';
import './MainAppBarLg.scss';
import PhoneIcon from '../../common/icons/PhoneIcon';
import PinIcon from '../../common/icons/PinIcon';
import HeartIcon from '../../common/icons/HeartIcon';
import accountTypes from '../../const-values/accountTypes';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import { useNavigate } from 'react-router-dom';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import dropdownIcon from '../../assets/imgs/icons/appbar-dropdown-icon.svg';
import MainAppBarSearch from './MainAppBarSearch';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import AppNotificationsDropdownMenu from './AppNotificationsDropdownMenu';
import signinSignupImg from '../../assets/imgs/icons/login-signup-img.png';
import coinImg from '../../assets/imgs/icons/coin-img.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import quoteTypes from '../../const-values/quoteTypes';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';
import ArrowDownIcon from '../../common/icons/ArrowDownIcon';

const MainAppBarLg = ({ className, exceeds0 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const user = useSelector((store) => store.user.user);
  const { allFetchedFilterCats, location, appCity } =
    useContext(SharedAppContext);

  const renderMenuSubMenu = (menu) => {
    if (menu?.length > 0) {
      return menu.map((filterCat) => {
        if (filterCat?.cats?.length > 0) {
          return (
            <Dropdown
              trigger={['click']}
              placement="bottom"
              arrow
              key={filterCat?.id}
              menu={{
                items: [
                  ...filterCat.cats.map((cat) => ({
                    className: 'nav-link-li',
                    key: cat?.id,
                    label: cat?.name,
                    onClick: () => {
                      navigate(
                        `${generalRouterLinks?.generalProductsRoute}?cat_id=${
                          cat?.id || ''
                        }&catFilter_id=${filterCat?.id || ''}`
                      );
                    }
                  }))
                ]
              }}
            >
              <div className="main-nav-link-div">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4
                  }}
                >
                  {filterCat?.name}
                  {filterCat?.cats?.length > 0 && (
                    <img src={dropdownIcon} alt="drop" />
                  )}
                </span>
                <div className="active-img-wrap"></div>
              </div>
            </Dropdown>
          );
        }
        return (
          <div
            className="main-nav-link-div"
            key={filterCat?.id}
            onClick={() => {
              navigate(
                `${generalRouterLinks?.generalProductsRoute}?catFilter_id=${
                  filterCat?.id || ''
                }`
              );
            }}
          >
            <span>{filterCat?.name}</span>
            <div className="active-img-wrap"></div>
          </div>
        );
      });
    }
  };

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(i18n, t, user).map(
          ({ id, name, link, sub, icon, ...props }) => (
            <li key={id}>
              {sub?.length > 0 ? (
                <div className={`main-nav-link`} {...props}>
                  {sub?.length > 0 ? (
                    <Dropdown
                      trigger={['click']}
                      arrow
                      placement="bottom"
                      menu={{
                        items: sub.map((d) => {
                          return {
                            key: d.key,
                            label: (
                              <RouterLink
                                style={{
                                  padding: '4px 12px',
                                  fontWeight: 'bold',
                                  minWidth: 128,
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}
                                to={d?.to}
                              >
                                {d.label}
                              </RouterLink>
                            )
                          };
                        })
                      }}
                    >
                      <div className="main-nav-link-div">
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {name}{' '}
                          <ArrowDownIcon
                            style={{
                              fill: 'currentColor'
                            }}
                          />
                        </span>
                        <div className="active-img-wrap"></div>
                      </div>
                    </Dropdown>
                  ) : (
                    <div className="main-nav-link-div">
                      <span>{name}</span>
                      <div className="active-img-wrap"></div>
                    </div>
                  )}
                </div>
              ) : (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'active-link main-nav-link' : 'main-nav-link'
                  }
                  to={link}
                  {...props}
                >
                  {sub?.length > 0 ? (
                    <Dropdown
                      arrow
                      menu={{
                        items: sub.map((d) => {
                          return {
                            key: d.key,
                            label: <RouterLink to={d?.to}>{d.label}</RouterLink>
                          };
                        })
                      }}
                    >
                      <div className="main-nav-link-div">
                        <span>{name}</span>
                        <div className="active-img-wrap"></div>
                      </div>
                    </Dropdown>
                  ) : (
                    <div className="main-nav-link-div">
                      <span>{name}</span>
                      <div className="active-img-wrap"></div>
                    </div>
                  )}
                </NavLink>
              )}
            </li>
          )
        )}
        {/*  */}
        {(user?.type == accountTypes()?.user || !user) &&
          renderMenuSubMenu(allFetchedFilterCats)}

        <li className="public-quotes-btn" key="public_quotes_key">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'active-link main-nav-link' : 'main-nav-link'
            }
            to={generalRouterLinks?.publicQuotesRoute}
          >
            {t('main_app_bar_links.publicQuotes')}
          </NavLink>
        </li>

        {/* <li key="blogs_key">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'active-link main-nav-link' : 'main-nav-link'
            }
            to={generalRouterLinks?.blogsRoute}
          >
            <div className="main-nav-link-div">
              <span>{t('main_app_bar_links.blogs')}</span>
              <div className="active-img-wrap"></div>
            </div>
          </NavLink>
        </li> */}
      </ul>
    );
  };

  const renderSignSignupBtn = () => {
    return (
      <RouterLink className="signin-signup-link" to={routerLinks?.signinPage}>
        <img src={signinSignupImg} alt="signin" />
        {t('main_app_bar_links.signin_signup')}
      </RouterLink>
    );
  };
  const renderBottomAppBar = () => {
    // check if no user or there is a user of type client or employee
    if (!user || user?.accountType == accountTypes()?.user) {
      // check if there is a user => active or not
      if (!user?.activated) {
        return (
          <div className="main-search-lang-wrap">
            <MainAppBarSearch location={location} />

            <div className="side-btns-wrapper">
              {renderSignSignupBtn()}
              <button
                onClick={() => {
                  if (user) {
                    dispatch(setQuoteType(quoteTypes?.publicQuote));
                    dispatch(setQuoteModalOpened(true));
                  } else {
                    dispatch(setNotAuthedModalOpened(true));
                  }
                }}
                className="request-btn"
              >
                <img src={coinImg} alt="coin" />
                {t('main_app_bar_links.request')}
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="main-search-lang-wrap">
            <MainAppBarSearch location={location} />
            <div
              style={{
                display: 'flex',
                gap: 22,
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <AppNotificationsDropdownMenu parentClassName="main-search-lang-wrap" />

              <RouterLink
                style={{ display: 'flex', alignItems: 'center' }}
                to={userRouterLinks?.myFavRoute}
              >
                <HeartIcon />
              </RouterLink>

              {/* <RouterLink
                style={{ display: 'flex', alignItems: 'center' }}
                to={generalRouterLinks?.userCartRoute}
              >
                <Badge count={12}>
                  <CartIcon />
                </Badge>
              </RouterLink> */}
              <MainAppProfileMenu />
            </div>
          </div>
        );
      }
    } else {
      // means there is a user of type company
      // then check if the user is active or not
      if (!user.activated) {
        return (
          <div className="merchent-signin-wrapper">{renderSignSignupBtn()}</div>
        );
      } else {
        return (
          <div className="merchent-notifications-profile-wrapper">
            <div
              style={{
                display: 'flex',
                gap: 22,
                alignItems: 'center'
              }}
            >
              <AppNotificationsDropdownMenu parentClassName="merchent-notifications-profile-wrapper" />
              <MainAppProfileMenu />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div
      className={`${className} ${
        user?.accountType == accountTypes()?.user || !user ? 'user-nav' : ''
      }`}
    >
      <div className="fixed-top-appbar">
        <div className="custom-container">
          <div className="section-content">
            <div className="email-phone-wrapper">
              {true && (
                <a href={`tel:+${123423434}`}>
                  <PhoneIcon color="#fff" />
                  <span>{1234123444}</span>
                </a>
              )}
            </div>
            <div className="other-content">
              {appCity && (
                <div className="deliver-wrap">
                  <PinIcon color="#fff" />
                  <p>
                    {t('top_appbar.deliver')} <span>{appCity}</span>
                  </p>
                </div>
              )}
              <div className="language-wrap">
                <LanguageButton />
              </div>
              <div className="dliver-wrap">{t('currency.sar')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-container" style={{ display: 'grid' }}>
        <div
          className={`nav-content-wrapper ${
            pathname !== '/' ? 'not-home' : ''
          }`}
        >
          <Logo className="main-app-bar-logo" />
          <div
            className={`search-links-wrap ${
              accountTypes()?.user === user?.accountType || !user
                ? 'with-search-input'
                : 'without-search-input'
            }`}
          >
            {renderBottomAppBar()}
            <div className="nav-lang-wrapper bottom-navs">
              {mainAppBarLinks?.length > 0 && renderNavLinks()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAppBarLg;
