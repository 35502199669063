import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  setNegotiationModalOpenedAction,
  setNegotiationPriceAction
} from '../../redux/cart-slice/cartSlice';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import './NegotiationModal.scss';
import quoteTypes from '../../const-values/quoteTypes';

const NegotiationModal = () => {
  const { t, i18n } = useTranslation();
  const negotiationModalOpened = useSelector(
    (store) => store.cart.negotiationModalOpened
  );
  const dispatch = useDispatch();
  const sharedT = (key) => t(`cartPage.${key}`);

  const schema = Yup.object().shape({
    price: Yup.string()
      .required(sharedT('negotiationForm.price.errors.required'))
      .matches(
        /^(\d+)?(\.\d+)?$/,
        sharedT('negotiationForm.price.errors.numbersOnly')
      )
      .test(
        'price',
        sharedT('negotiationForm.price.errors.invalidPrice'),
        (v, context) => {
          let result = true;
          if (!v || (v && parseFloat(v) <= 0)) {
            result = false;
          }
          return result;
        }
      )
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      price: ''
    }
  });

  const onSubmit = (data) => {
    dispatch(setNegotiationPriceAction(data.price));
    dispatch(setNegotiationModalOpenedAction(false));
    dispatch(setQuoteType(quoteTypes?.supplierQuote));
    dispatch(setQuoteModalOpened(true));
  };

  const [form] = Form.useForm();
  return (
    <>
      <Modal
        destroyOnClose
        className="negotiation-modal"
        closeIcon={<img src={imgsSrcs.closeCircleImg} alt="close" />}
        width="96%"
        style={{ maxWidth: '732px' }}
        title={
          <div className="modal-h">
            <p className="main-header-t">{sharedT('negotiationPrice')}</p>
            <p className="sub-header-t">{sharedT('addNegoPrice')}</p>
          </div>
        }
        open={negotiationModalOpened}
        onOk={() => {
          dispatch(setNegotiationModalOpenedAction(false));
          dispatch(setQuoteType(''));
        }}
        onCancel={() => {
          dispatch(setNegotiationModalOpenedAction(false));
          dispatch(setQuoteType(''));
        }}
        footer={false}
      >
        <Form
          className="negotiate-form custom-shared-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="form-header">
            <p className="sub-title">{sharedT('addNegoFormTitle')}</p>
          </div>

          <div className="form-body">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="price"
                type="text"
                errorMsg={errors?.price?.message}
                validateStatus={errors?.price ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {sharedT('negotiationForm.price.label')}
              </p>
            </div>

            <div className="action-btns">
              <button
                onClick={() => {
                  dispatch(setNegotiationPriceAction(''));
                  dispatch(setNegotiationModalOpenedAction(false));
                }}
                type="button"
                className="cancel-btn"
              >
                {sharedT('negotiationForm.cancel')}
              </button>
              <button type="submit" className="submit-btn">
                {sharedT('negotiationForm.next')}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default NegotiationModal;
