/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import { Link, Link as RouterLink } from 'react-router-dom';
import './SignupForm.scss';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import routerLinks from '../../components/app/routerLinks';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import TermsModal from './TermsModal';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import useSignupEmailPassword from '../signin-page/useSignupEmailPassword';
import { useDispatch, useSelector } from 'react-redux';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import accountTypes, {
  getCompanyTypesArr
} from '../../const-values/accountTypes';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';
import { useEffect } from 'react';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const MerchentSignupForm = () => {
  const { i18n, t } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  //
  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  useContext(ForgetPasswordContext);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    managerName: Yup.string().required(generalrequiredErrStr('managerName')),
    companyName: Yup.string().required(generalrequiredErrStr('companyName')),
    companyType_id: Yup.string().required(
      generalrequiredErrStr('companyType_id')
    ),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    services: Yup.array()
      .min(1, generalrequiredErrStr('services'))
      .required(generalrequiredErrStr('services')),
    subServices: Yup.array()
      .min(1, generalrequiredErrStr('services'))
      .required(generalrequiredErrStr('services')),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email')),
    password: Yup.string().required(generalrequiredErrStr('password')),
    password_confirmation: Yup.string()
      .required(generalrequiredErrStr('password_confirmation'))
      .oneOf(
        [Yup.ref('password')],
        generalTypeErrorStr('password_confirmation')
      ),
    terms: Yup.boolean()
      .required('يجب الموافقة على الشروط والاحكام اولا')
      .oneOf([true], 'يجب الموافقة على الشروط الاحكام لاستكمال التسجيبل')
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      managerName: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: '',
      country_id: '',
      city_id: '',
      companyName: '',
      companyType_id: '',
      services: [],
      subServices: [],
      remember: true
    }
  });
  const [termsModalOpened, setTermsModalOpened] = useState(false);
  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);
  const { signMeUpWithEmailPassword } = useSignupEmailPassword();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    signMeUpWithEmailPassword({
      ...data,
      userType: accountTypes()?.company,
      phone,
      countryCode,
      accountType: accountTypes()?.company
    });
  };

  const dispatch = useDispatch();
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );

  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('services')?.length >= 0 && services?.length > 0) {
      let foundServObj = null;
      const arr = [];
      for (let v of watch('services')) {
        foundServObj = services.find((obj) => obj?.id == v);
        if (
          foundServObj &&
          typeof foundServObj === 'object' &&
          foundServObj?.subServices?.length >= 0
        ) {
          arr.push(...foundServObj.subServices);
        }
      }
      setAllSubServicesArr(arr);
      setValue('subServices', []);
    }
  }, [watch('services')]);

  // console.log('watch : ', watch());
  // console.log('all sub : ', allSubServicesArr);
  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signup-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signup_form.newAcc')}</p>
          <div>
            <p className="sub-title">{t('signup_form.subTitle')}</p>
          </div>
        </div>

        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="managerName"
              type="text"
              errorMsg={errors?.managerName?.message}
              validateStatus={errors?.managerName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('managerName')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="companyName"
              type="text"
              errorMsg={errors?.companyName?.message}
              validateStatus={errors?.companyName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('companyName')}</p>
          </div>

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name={`companyType_id`}
              errorMsg={errors?.companyType_id && errors.companyType_id.message}
              validateStatus={errors?.companyType_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                getCompanyTypesArr(t)?.length > 0 &&
                getCompanyTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="signup-form"
            />
            <p className="custom-label">{generalLabelStr('companyType_id')}</p>
          </div>

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="services"
            label={generalLabelStr('services')}
            mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subServices"
            label={generalLabelStr('subServices')}
            mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={allSubServicesArr}
          />

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="email"
              type="text"
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('email')}</p>
          </div>

          <div
            className={`country-code-phone-wrap ${
              watch('phone') ? 'focused' : ''
            } ${i18n.dir()} ${errors?.phone?.message ? 'error' : ''}`}
          >
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    // placeholder={generalLabelStr('phone')}
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    countryCallingCodeEditable={false}
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="custom-label">{generalLabelStr('phone')}</p>
            <p className="error-p">{errors?.phone?.message}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            parentClassName="signup-form"
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{generalLabelStr('password')}</p>
            </div>

            {watch('password') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password_confirmation"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.password_confirmation?.message}
                validateStatus={errors?.password_confirmation ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {generalLabelStr('password_confirmation')}
              </p>
            </div>

            {watch('password_confirmation') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="terms-checkbox-wrap">
            <AntdCheckbox
              name="terms"
              control={control}
              label={t('signup_form.accept')}
              errorMsg={errors?.remember?.message}
              validateStatus={errors?.remember ? 'error' : ''}
            />

            <button
              className="terms-btn"
              onClick={(e) => {
                setTermsModalOpened(true);
                e.preventDefault();
              }}
              to={generalRouterLinks?.refundTermsRoute}
            >
              {t('signup_form.terms')}
            </button>
          </div>
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {isSubmitting
              ? t('signup_form.submit_btn.loading')
              : t('signup_form.submit_btn.label')}
          </Button>
        </div>
        <div className="already-have-account">
          <span>{t('signup_form.haveAccount')} </span>{' '}
          <RouterLink to={routerLinks?.signinPage}>
            {t('signup_form.signin')}
          </RouterLink>
        </div>

        <Link
          style={{
            textAlign: 'center',
            padding: '0 10px',
            marginTop: 12,
            color: '#246db4',
            fontWeight: 'bold'
          }}
          to={routerLinks?.userSignupRoute}
          className="antother-signup"
        >
          {t('signup_form.registerUser')}
        </Link>
      </Form>

      {termsModalOpened && (
        <TermsModal
          modalOpened={termsModalOpened}
          setModalOpened={setTermsModalOpened}
        />
      )}

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default MerchentSignupForm;
