import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './TermsModal.scss';
const TermsModal = ({ modalOpened, setModalOpened }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="shared-custom-modal signup-terms-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title={t('signup_form.terms')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem non
        atque quasi laudantium repudiandae repellat, fuga optio beatae modi
        deserunt eligendi ut. Aspernatur, fugit? Totam, officia. Fugiat
        molestiae nihil beatae. Quam quae voluptas fugit, obcaecati qui totam
        cumque quia. Laboriosam, alias quas, voluptatum hic ullam id enim omnis
        et at quo repudiandae nam fuga pariatur voluptas ut, modi iure saepe!
        Recusandae nam deserunt ducimus necessitatibus incidunt quas itaque
        labore perferendis dolorum quaerat ex explicabo laborum vel beatae
        possimus consequuntur laudantium voluptatibus unde sit voluptatem porro,
        sequi aspernatur eum alias! Illum. Corporis optio, eum voluptatem eius
        nobis sint dolorum nemo rem, soluta libero amet itaque voluptatum minus
        repellat ipsa commodi sapiente esse repudiandae. Nam unde illum, itaque
        ea ut fugiat est. Perferendis facilis enim optio quis mollitia officia
        praesentium magnam aperiam velit quia fugiat soluta ratione, incidunt
        ducimus. Perferendis veritatis voluptate rerum, officiis quo nesciunt
        blanditiis magnam, cupiditate optio nostrum laudantium? Tenetur esse,
        obcaecati laboriosam sit quae possimus ea officiis commodi quos
        temporibus distinctio quia minima itaque porro laudantium est architecto
        quisquam dolores! Suscipit magni adipisci quasi illo consequuntur
        voluptatum illum? Nam sit ipsa deserunt earum cupiditate quam adipisci
        molestiae facere ipsum atque odio consectetur sapiente magni inventore
        aliquid alias deleniti, odit autem, ab a minima quidem tempora.
        Delectus, veritatis error. Quibusdam dicta eaque eum harum iure hic
        inventore doloremque non temporibus quidem, natus, facilis officia rerum
        accusamus minima magnam maiores soluta alias magni nisi consequuntur,
        esse sint blanditiis. Molestias, nesciunt. Quo, omnis itaque. Quia
        perferendis amet eveniet impedit? Odit perferendis animi beatae mollitia
        laudantium. Eligendi illo asperiores quisquam quaerat, excepturi enim
        nulla ipsum veritatis dignissimos vitae consectetur unde corrupti vel?
        Possimus reiciendis distinctio qui ex facere! Nobis hic, iure, fuga nam
        possimus necessitatibus rem obcaecati sit nisi velit suscipit dolore,
        voluptates omnis iusto blanditiis. Voluptas qui commodi obcaecati
        voluptate earum.
      </p>
    </Modal>
  );
};

export default TermsModal;
