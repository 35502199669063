import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import Logo from '../../common/logo/Logo';
import routerLinks from '../../components/app/routerLinks';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import { removeCurrentUserAction } from '../../redux/user-slice/userSlice';
import ForgetPasswordFormEnterEmail from '../signin-page/ForgetPasswordFormEnterEmail';
import './ForgetPasswordPage.scss';

const ForgetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);
  const {
    forgetPasswordFormEnterEmailAppended,
    forgetPasswordFormEnterCodeAppended,
    isSubmitting,
    setUser
  } = useContext(ForgetPasswordContext);

  const dispatch = useDispatch();

  const renderExtraText = () => {
    if (forgetPasswordFormEnterEmailAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('enterEmail.mainTitle')}</h2>
          <p className="sub-title">{sharedT('enterEmail.subTitle')}</p>
        </div>
      );
    } else if (forgetPasswordFormEnterCodeAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('verification.mainTitle')}</h2>
          <p className="sub-title">{sharedT('verification.subTitle')}</p>
        </div>
      );
    } else if (forgetPasswordFormEnterCodeAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('change.mainTitle')}</h2>
          <p className="sub-title">{sharedT('change.subTitle')}</p>
        </div>
      );
    }
  };

  return (
    <div className="forget-password-page">
      <div className="custom-container">
        <div className="page-content-wrap">
          <Logo className="app-logo" />
          <div className="page-form-wrap">
            {renderExtraText()}

            <ForgetPasswordFormEnterEmail />

            <button
              className="different-account-btn"
              to={routerLinks?.signinPage}
              onClick={() => {
                setUser(null);
                dispatch(removeCurrentUserAction());
                navigate(routerLinks?.signinPage);
              }}
            >
              remember your password... click here
            </button>
          </div>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default ForgetPasswordPage;
