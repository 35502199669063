import { Modal } from 'antd';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompaniesModalOpenedAction,
  setSelectedCompanyAction,
  setSelectedCompOffersModalOpenedAction
} from '../../redux/user-orders-slice/userOrdersSlice';
import { LoadingOutlined } from '@ant-design/icons';
import './CompaniesModal.scss';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { userGetOrderCompaniesAction } from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
import { useTranslation } from 'react-i18next';
import CustomImage from '../../common/custom-image/CustomImage';
import { getCompanyTypesArr } from '../../const-values/accountTypes';
import { useMemo } from 'react';

const CompaniesModal = () => {
  const dispatch = useDispatch();
  const companiesModalOpened = useSelector(
    (store) => store.userOrders.companiesModalOpened
  );
  const isLoadingOrderCompanies = useSelector(
    (store) => store.userOrders.isLoadingOrderCompanies
  );
  const fetchedOrderCompanies = useSelector(
    (store) => store.userOrders.fetchedOrderCompanies
  );
  const fetchSingleOrderCount = useSelector(
    (store) => store.userOrders.fetchSingleOrderCount
  );
  const { i18n } = useTranslation();
  const params = useParams();

  useEffect(() => {
    if (companiesModalOpened) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [companiesModalOpened]);

  useEffect(() => {
    let isMounted = true;
    if (params?.order_id && isMounted && companiesModalOpened)
      dispatch(
        userGetOrderCompaniesAction({
          filterObj: {
            order_id: params?.order_id || ''
          }
        })
      );

    return () => (isMounted = false);
  }, [i18n.language, fetchSingleOrderCount, companiesModalOpened]);

  const renderCompType = (type) => {
    const found = getCompanyTypesArr(t)?.find((obj) => obj?.value === type);
    if (found) return found?.name;
    return null;
  };

  const CompCard = ({ comp }) => {
    return (
      <div
        onClick={() => {
          dispatch(setSelectedCompanyAction(comp));
          dispatch(setSelectedCompOffersModalOpenedAction(true));
        }}
        className="order-comp-card"
      >
        <div className="img-name-date-wrap">
          <div className="img-name">
            <CustomImage className="prof-img" src={comp?.image} />
            <div className="names-wrapper">
              <div className="name">{comp?.companyData?.companyName}</div>
              <div className="manager-name">{comp?.name}</div>
            </div>
          </div>

          <div className="comp-type">{renderCompType(comp?.companyType)}</div>
        </div>
      </div>
    );
  };

  const renderModalContent = useMemo(
    () => () => {
      if (isLoadingOrderCompanies) {
        return (
          <div
            style={{
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <LoadingOutlined />
          </div>
        );
      } else if (
        fetchedOrderCompanies?.length === 0 ||
        !fetchedOrderCompanies
      ) {
        return (
          <h2
            style={{
              textAlign: 'center',
              fontSize: 20,
              margin: '42px 0'
            }}
          >
            {t('singleOrderPage.noCompanies')}
          </h2>
        );
      } else if (fetchedOrderCompanies?.length > 0) {
        return (
          <div className="comp-list">
            {fetchedOrderCompanies.map((comp) => (
              <CompCard key={comp?.id} comp={comp} />
            ))}
          </div>
        );
      }
      return null;
    },
    [fetchedOrderCompanies]
  );

  return (
    <Modal
      className={`shared-custom-modal companies-modal`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={t('offersModal.compResponses')}
      open={companiesModalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        dispatch(setCompaniesModalOpenedAction(false));
      }}
      footer={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default CompaniesModal;
