import React from 'react';

const PinIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_125_4030)">
        <path
          d="M12.0001 6C11.209 6 10.4356 6.2346 9.77784 6.67412C9.12005 7.11365 8.60736 7.73836 8.30461 8.46927C8.00186 9.20017 7.92264 10.0044 8.07698 10.7804C8.23132 11.5563 8.61229 12.269 9.1717 12.8284C9.73111 13.3878 10.4438 13.7688 11.2198 13.9231C11.9957 14.0775 12.8 13.9983 13.5309 13.6955C14.2618 13.3928 14.8865 12.8801 15.326 12.2223C15.7655 11.5645 16.0001 10.7911 16.0001 10C16.0001 8.93913 15.5787 7.92172 14.8286 7.17157C14.0784 6.42143 13.061 6 12.0001 6ZM12.0001 12C11.6046 12 11.2179 11.8827 10.889 11.6629C10.5601 11.4432 10.3037 11.1308 10.1524 10.7654C10.001 10.3999 9.96138 9.99778 10.0386 9.60982C10.1157 9.22186 10.3062 8.86549 10.5859 8.58579C10.8656 8.30608 11.222 8.1156 11.6099 8.03843C11.9979 7.96126 12.4 8.00087 12.7655 8.15224C13.1309 8.30362 13.4433 8.55996 13.6631 8.88886C13.8828 9.21776 14.0001 9.60444 14.0001 10C14.0001 10.5304 13.7894 11.0391 13.4143 11.4142C13.0393 11.7893 12.5306 12 12.0001 12Z"
          fill={color || '#c4c4c4'}
        />
        <path
          d="M11.9999 24.0003C11.1579 24.0046 10.3271 23.8071 9.57701 23.4243C8.82697 23.0416 8.17954 22.4847 7.68895 21.8003C3.87795 16.5433 1.94495 12.5913 1.94495 10.0533C1.94495 7.38653 3.00431 4.829 4.88999 2.94332C6.77567 1.05764 9.33319 -0.00172424 11.9999 -0.00172424C14.6667 -0.00172424 17.2242 1.05764 19.1099 2.94332C20.9956 4.829 22.0549 7.38653 22.0549 10.0533C22.0549 12.5913 20.1219 16.5433 16.3109 21.8003C15.8204 22.4847 15.1729 23.0416 14.4229 23.4243C13.6728 23.8071 12.842 24.0046 11.9999 24.0003ZM11.9999 2.18128C9.91236 2.18366 7.91096 3.014 6.43482 4.49015C4.95867 5.96629 4.12833 7.96769 4.12595 10.0553C4.12595 12.0653 6.01895 15.7823 9.45495 20.5213C9.74664 20.9231 10.1293 21.2501 10.5717 21.4755C11.014 21.701 11.5034 21.8186 11.9999 21.8186C12.4964 21.8186 12.9859 21.701 13.4282 21.4755C13.8706 21.2501 14.2533 20.9231 14.5449 20.5213C17.9809 15.7823 19.8739 12.0653 19.8739 10.0553C19.8716 7.96769 19.0412 5.96629 17.5651 4.49015C16.0889 3.014 14.0875 2.18366 11.9999 2.18128Z"
          fill={color || '#c4c4c4'}
        />
      </g>
      <defs>
        <clipPath id="clip0_125_4030">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinIcon;
