/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axiosDefaults from '../../axiosDefaults';
import '../../i18n';
import {
  getCompanyTypesAction,
  getCountriesAction,
  getHomeAction,
  getServicesAction,
  getSliderAction,
  getUnitsAction
} from '../../redux/general-slice/generalSliceThunkApis';
import {
  removeCurrentUserAction,
  setNotAuthedModalOpened
} from '../../redux/user-slice/userSlice';
import DefaultAppLayout from './app-layout/DefaultAppLayout';
import Routes from './Routes';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const user = useSelector((store) => store.user.user);
  console.log('user : ', user);
  axiosDefaults(
    user,
    () => dispatch(removeCurrentUserAction()),
    (v) => dispatch(setNotAuthedModalOpened(v)),
    i18n.language
  );
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  // get general app data
  useEffect(() => {
    dispatch(getHomeAction());
    dispatch(getCountriesAction());
    dispatch(
      getServicesAction({
        filterObj: null
      })
    );
    dispatch(getCompanyTypesAction());
    dispatch(getSliderAction());
    dispatch(getUnitsAction());
  }, []);

  const navigate = useNavigate();
  // useEffect(() => {
  //   let isMounted = true;
  //   if (user && user.activated && isMounted) {
  //     dispatch(userInfoAction()).then((obj) => {
  //       const res = obj?.payload?.res;
  //       if (res?.data?.data && res?.data?.status === 1) {
  //         if (res?.data?.data?.status === 0) {
  //           dispatch(
  //             setUserToStateAction({
  //               ...res?.data.data,
  //               accountType: user.accountType,
  //               companyType: user.companyType,
  //               activated: false,
  //               remember: user?.remember || false,
  //               firstTime: user?.firstTime || false
  //             })
  //           );
  //           navigate(routerLinks?.activateAccountRoute);
  //         } else if (res?.data?.data?.status === 1) {
  //           if (user.remember) {
  //             dispatch(
  //               setCurrentUserAction({
  //                 ...res?.data?.data,
  //                 token: user?.token,
  //                 accountType: user.accountType,
  //                 companyType: user.companyType,
  //                 activated: user.activated,
  //                 remember: user?.remember || false,
  //                 firstTime: user?.firstTime || false
  //               })
  //             );
  //           } else {
  //             dispatch(
  //               setUserToStateAction({
  //                 ...res.data.data,
  //                 token: user?.token,
  //                 accountType: user.accountType,
  //                 companyType: user.companyType,
  //                 activated: user.activated,
  //                 remember: user?.remember || false,
  //                 firstTime: user?.firstTime || false
  //               })
  //             );
  //             // if (
  //             //   data?.accountType == accountTypes()?.user ||
  //             //   res?.data?.data?.type == accountTypes()?.user
  //             // ) {
  //             //   navigate(routerLinks?.homePage);
  //             // } else {
  //             //   navigate(merchentRouterLinks?.merchentDashboard);
  //             // }
  //           }
  //         }
  //       }
  //       // else {
  //       //   removeCurrentUser();
  //       //       navigate(routerLinks?.signinPage);
  //       // }
  //     });
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  return (
    <div className="app">
      <DefaultAppLayout>
        <Routes />
      </DefaultAppLayout>

      <ToastContainer closeButton={false} />
    </div>
  );
}

export default App;
