import { useContext } from 'react';
import accountTypes from '../../../const-values/accountTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import AboutUsPage from '../../../pages/about-us-page/AboutUsPage';
import AdvancedSearchPage from '../../../pages/advanced-search-page/AdvancedSearchPage';
import ContractorsPage from '../../../pages/companies-page/ContractorsPage';
import EngineeringOfficesPage from '../../../pages/companies-page/EngineeringOfficesPage';
import SuppliersPage from '../../../pages/companies-page/SuppliersPage';
import SupportiveServicesPage from '../../../pages/companies-page/SupportiveServicesPage';
import ContactPage from '../../../pages/contact-page/ContactPage';
import PricesPage from '../../../pages/prices-page/PricesPage';
import PublicQuotesPage from '../../../pages/public-quotes-page/PublicQuotesPage';
import PublicSingleQuotePage from '../../../pages/public-single-quote-page/PublicSingleQuotePage';
import SingleCompanyPage from '../../../pages/single-company-page/SingleCompanyPage';
import CartPage from '../../../pages/supplier-products-page/CartPage';
import SupplierProductsPage from '../../../pages/supplier-products-page/SupplierProductsPage';
import generalRoute from '../../../utils/generalRoute';
import routerLinks from '../routerLinks';
import generalRouterLinks from './gerneralRouterLinks';

const GeneralRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  return [
    generalRoute(
      generalRouterLinks?.advancedSearch,
      <AdvancedSearchPage />,
      'advanced_search_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.aboutUsRoute,
      <AboutUsPage />,
      'about_us_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.company, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.contactUsRoute,
      <ContactPage />,
      'contact_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.company, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.pricesRoute,
      <PricesPage />,
      'prices_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.publicQuotesRoute,
      <PublicQuotesPage />,
      'public_quotes_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.company, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.singlePublicQuoteRoute(),
      <PublicSingleQuotePage />,
      'single_public_quote_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.company, accountTypes()?.employee]
    ),
    //
    generalRoute(
      generalRouterLinks?.suppliersRoute,
      <SuppliersPage />,
      'companies_suppliers_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.contractorsRoute,
      <ContractorsPage />,
      'companies_contractors_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.engineeringOfficesRoute,
      <EngineeringOfficesPage />,
      'companies_office_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.supportiveServicesRoute,
      <SupportiveServicesPage />,
      'companies_supportive_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.singleCompanyRoute,
      <SingleCompanyPage />,
      'single_comp_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.supplierProductsRoute(),
      <SupplierProductsPage />,
      'supplier_comp_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    //
    generalRoute(
      generalRouterLinks?.userCartRoute(),
      <CartPage />,
      'cart_route_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    //
    generalRoute(
      generalRouterLinks?.singleBlogRoute(),
      <h1>Single blog page</h1>,
      'single_blog_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.company, accountTypes()?.employee]
    )

    //
  ];
};

export default GeneralRoutes;
