import { useTranslation } from 'react-i18next';
import './HomeRequestsSection.scss';
import appStoreImg from '../../assets/imgs/icons/app-store.png';
import googlePlayImg from '../../assets/imgs/icons/google-play.png';
import reqMockupImg from '../../assets/imgs/patterns/home-req-mockup.png';

const HomeRequestsSection = () => {
  const { t } = useTranslation();
  return (
    <section className="home-req-section">
      <div className="custom-container">
        <div className="sec-content">
          <div className="text-wrap">
            <div className="main-t">{t('homeReq.title')}</div>
            <div className="sub-t">{t('homeReq.subTitle')}</div>

            <div className="get-app">
              <p className="get-t">{t('homeReq.getApp')}</p>
              <div className="stores-wrap">
                <img src={googlePlayImg} alt="google play" />
                <img src={appStoreImg} alt="app store" />
              </div>
            </div>
          </div>

          <div className="mockup-wrap">
            <img src={reqMockupImg} alt="send request" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeRequestsSection;
