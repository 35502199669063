/* eslint-disable eqeqeq */
import CatsIcon from '../common/icons/CatsIcon';
import ContactMessagesIcon from '../common/icons/ContactMessagesIcon';
import FaqIcon from '../common/icons/FaqIcon';
import GroupIcon from '../common/icons/GroupIcon';
import HomeIcon from '../common/icons/HomeIcon';
import OffersIcon from '../common/icons/OffersIcon';
import PackageIcon from '../common/icons/PackageIcon';
import ServicesIcon from '../common/icons/ServicesIcon';
import SettingsIcon from '../common/icons/SettingsIcon';
import SlideShowIcon from '../common/icons/SlideShowIcon';
import SocialLinksIcon from '../common/icons/SocialLinksIcon';
import ValuesIcon from '../common/icons/ValuesIcon';
import generalRouterLinks from '../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../components/app/routerLinks';
import accountTypes from './accountTypes';

const appPages = (i18n, t, user) => ({
  homePage: {
    id: 'main_app_bar_link_1',
    name: t('main_app_bar_links.home'),
    link: routerLinks?.homePage,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  companiesPage: {
    id: 'main_app_bar_link_companies',
    name: t('main_app_bar_links.companies'),
    link: '/companies',
    sub: [
      {
        key: 'supp_sub_key',
        label: t('main_app_bar_links.suppliers'),
        to: generalRouterLinks?.suppliersRoute
      },
      {
        key: 'suppliers_sub_key',
        label: t('main_app_bar_links.contractors'),
        to: generalRouterLinks?.contractorsRoute
      },
      {
        key: 'office_sub_key',
        label: t('main_app_bar_links.office'),
        to: generalRouterLinks?.engineeringOfficesRoute
      },
      {
        key: 'supportiveServices_sub_key',
        label: t('main_app_bar_links.supportive'),
        to: generalRouterLinks?.supportiveServicesRoute
      }
    ],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  aboutUsPage: {
    id: 'main_app_bar_link_about',
    name: t('main_app_bar_links.aboutUs'),
    link: generalRouterLinks?.aboutUsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  suppliersPage: {
    id: 'main_app_bar_link_suppliers',
    name: t('main_app_bar_links.suppliers'),
    link: generalRouterLinks?.suppliersRoute,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  contractorsPage: {
    id: 'main_app_bar_link_contractors',
    name: t('main_app_bar_links.contractors'),
    link: generalRouterLinks?.contractorsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  pricesPage: {
    id: 'main_app_bar_link_prices',
    name: t('main_app_bar_links.prices'),
    link: generalRouterLinks?.pricesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  contactUsPage: {
    id: 'main_app_bar_link_contact',
    name: t('main_app_bar_links.contactUs'),
    link: generalRouterLinks?.contactUsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  todayOffers: {
    id: 'main_app_bar_link_2',
    name: t('main_app_bar_links.today_offers'),
    link: routerLinks?.todayOffersRoute,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  topSaloons: {
    id: 'main_app_bar_link_222002',
    name: t('main_app_bar_links.top_saloons'),
    link: routerLinks?.topSaloonsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  // blogsPage: {
  //   id: 'main_app_bar_blogs_route',
  //   name: t('main_app_bar_links.top_saloons'),
  //   link: generalRouterLinks?.blogsRoute,
  //   sub: [],
  //   icon: ({ ...props }) => (
  //     <SlideShowIcon
  //       width={20}
  //       height={20}
  //       style={{
  //         fontSize: '20px',
  //         verticalAlign: 'middle'
  //       }}
  //       {...props}
  //     />
  //   )
  // },
  merchentDashboard: {
    id: 'main_app_bar_link_merchent_dashboard',
    name: t('main_app_bar_links.myOrders'),
    link: routerLinks?.merchentDashboard,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  merchentStores: {
    id: 'main_app_bar_link_merchent_stores',
    // name: t('main_app_bar_links.slides'),
    name:
      user?.accountType == accountTypes()?.merchent
        ? t('main_app_bar_links.stores')
        : t('main_app_bar_links.employeeStores'),

    link: routerLinks?.merchentStores,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  merchentEmployees: {
    id: 'main_app_bar_link_merchent_employees',
    name: t('main_app_bar_links.employees'),
    link: routerLinks?.merchentEmployees,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  merchentSettings: {
    id: 'main_app_bar_link_merchent_settings',
    name: t('main_app_bar_links.accountSettings'),
    link: routerLinks?.merchentSettings,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  merchentOther: {
    id: 'main_app_bar_link_merchent_other',
    name: t('main_app_bar_links.other'),
    link: routerLinks?.merchentOther,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  merchentTutorial: {
    id: 'main_app_bar_link_merchent_tutorial',
    name: t('main_app_bar_links.videoTutorial'),
    link: routerLinks?.merchentTutorial,
    sub: [],
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },

  categoriesPage: {
    id: 'main_app_bar_link_3',
    name: t('main_app_bar_links.categories'),
    link: routerLinks?.categoriesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <CatsIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  servicesPage: {
    id: 'main_app_bar_link_4',
    name: t('main_app_bar_links.services'),
    link: routerLinks?.servicesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <ServicesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  clientsPage: {
    id: 'main_app_bar_link_5',
    name: t('main_app_bar_links.clients'),
    link: routerLinks?.clientsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <GroupIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  ordersPage: {
    id: 'main_app_bar_link_6',
    name: t('main_app_bar_links.orders'),
    link: routerLinks?.ordersRoute,
    sub: [],
    icon: ({ ...props }) => (
      <PackageIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  orderDetailsPage: (orderId) => ({
    id: 'main_app_bar_link_7',
    name: t('main_app_bar_links.order_details'),
    link: routerLinks?.orderDetailsRoute(orderId),
    sub: [],
    icon: ({ ...props }) => (
      <PackageIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  }),
  valuesPage: {
    id: 'main_app_bar_link_8',
    name: t('main_app_bar_links.ourValues'),
    link: routerLinks?.ourValuesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <ValuesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  faqPage: {
    id: 'main_app_bar_link_9',
    name: t('main_app_bar_links.faq'),
    link: routerLinks?.faqRoute,
    sub: [],
    icon: ({ ...props }) => (
      <FaqIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  contactMessagesPage: {
    id: 'main_app_bar_link_10',
    name: t('main_app_bar_links.contactMessages'),
    link: routerLinks?.contactMessagesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <ContactMessagesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  socialMediaLinksPage: {
    id: 'main_app_bar_link_11',
    name: t('main_app_bar_links.socialLinks'),
    link: routerLinks?.socialLinksRoute,
    sub: [],
    icon: ({ ...props }) => (
      <SocialLinksIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  packagesPage: {
    id: 'main_app_bar_link_12',
    name: t('main_app_bar_links.packages'),
    link: routerLinks?.packagesRoute,
    sub: [],
    icon: ({ ...props }) => (
      <ContactMessagesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  discountsPage: {
    id: 'main_app_bar_link_13',
    name: t('main_app_bar_links.discount'),
    link: routerLinks?.discountRoute,
    sub: [],

    icon: ({ ...props }) => (
      <OffersIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  settingsPage: {
    id: 'main_app_bar_link_14',
    name: t('main_app_bar_links.settings'),
    link: routerLinks?.settingsRoute,
    sub: [],
    icon: ({ ...props }) => (
      <SettingsIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  }
});

export default appPages;
